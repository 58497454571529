function isInt(n) {
  return n % 1 === 0;
}
export const isNumeric = (value) => {
  if (value === "") return true;

  return !isNaN(Number(value)) && /([0-9]*[.])?[0-9]+/.test(value);
};

export const currencyFormat = (num) => {
  try {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } catch (error) {
    return 0;
  }
};

export const calculateResultEachRow = (row, ExchangeRate) => {
  const detail = row;
  detail.Quantity = Number(detail.Quantity);
  const amountOC = Number(detail.UnitPriceOC) * Number(detail.Quantity);

  detail.AmountOC = amountOC;
  detail.Amount = amountOC * ExchangeRate;

  detail.OutwardPriceOC = Number(detail.UnitPriceOC);
  detail.OutwardPrice = Number(detail.UnitPriceOC) * ExchangeRate;

  detail.OutwardAmountOC = amountOC;
  detail.OutwardAmount = amountOC * ExchangeRate;

  detail.OutwardPriceConvertOC = amountOC;
  detail.OutwardPriceConvert = amountOC * ExchangeRate;

  detail.TurnOverAmountOC = amountOC;
  detail.TurnOverAmount = amountOC * ExchangeRate;

  return detail;
};

export const calculateResult = (result) => {
  const ret = result;
  const { ExchangeRate } = result.purcharInvoice;
  let TotalAmountOC = 0;
  let TotalVatAmountOC = 0;
  for (let i = 0; i < ret.purcharInvoiceDetails.length; i += 1) {
    const detail = calculateResultEachRow(
      ret.purcharInvoiceDetails[i],
      ExchangeRate
    );
    TotalAmountOC += detail.AmountOC;
    TotalVatAmountOC += detail.VATAmountOC;
    ret.purcharInvoiceDetails[i] = detail;
  }
  ret.purcharInvoice.TotalAmountOC = TotalAmountOC;
  ret.purcharInvoice.TotalTurnOverAmountOC = TotalAmountOC;

  ret.purcharInvoice.TotalVATAmountOC = TotalVatAmountOC;
  ret.purcharInvoice.TotalVATAmount = TotalVatAmountOC * ExchangeRate;

  ret.purcharInvoice.TotalAmount = TotalAmountOC * ExchangeRate;
  ret.purcharInvoice.TotalTurnOverAmount = TotalAmountOC * ExchangeRate;
  return ret;
};

export const calculateResultSellEachRow = (row, ExchangeRate) => {
  const detail = row;
  const amountOC = Number(detail.UnitPriceOC) * Number(detail.Quantity);
  detail.AmountOC = amountOC;
  detail.Amount = amountOC * ExchangeRate;

  // detail.OutwardPriceOC = Number(detail.UnitPriceOC);
  // detail.OutwardPrice = Number(detail.UnitPriceOC) * ExchangeRate;

  // detail.OutwardAmountOC = amountOC;
  // detail.OutwardAmount = amountOC * ExchangeRate;

  // detail.OutwardPriceConvertOC = amountOC;
  // detail.OutwardPriceConvert = amountOC * ExchangeRate;

  // detail.TurnOverAmountOC = amountOC;
  // detail.TurnOverAmount = amountOC * ExchangeRate;
  return detail;
};

export const calculateResultSell = (result) => {
  const ret = result;
  const { ExchangeRate } = result.saleInvoice;
  let TotalAmountOC = 0;
  let TotalVatAmountOC = 0;
  for (let i = 0; i < ret.saleInvoiceDetails.length; i += 1) {
    detail.Quantity = Number(detail.Quantity);
    const detail = calculateResultSellEachRow(
      ret.saleInvoiceDetails[i],
      ExchangeRate
    );
    TotalAmountOC += detail.AmountOC;
    TotalVatAmountOC += detail.VATAmountOC;

    ret.saleInvoiceDetails[i] = detail;
  }
  ret.saleInvoice.TotalAmountOC = TotalAmountOC;
  ret.saleInvoice.TotalTurnOverAmountOC = TotalAmountOC;

  ret.saleInvoice.TotalVATAmountOC = TotalVatAmountOC;
  ret.saleInvoice.TotalVATAmount = TotalVatAmountOC * ExchangeRate;

  ret.saleInvoice.TotalAmount = TotalAmountOC * ExchangeRate;
  ret.saleInvoice.TotalTurnOverAmount = TotalAmountOC * ExchangeRate;
  return ret;
};

export function normalizeVietnameseText(text) {
  // Loại bỏ dấu tiếng Việt
  const removeVietnameseTones = (str) => {
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Loại bỏ dấu
    str = str.replace(/đ/g, "d").replace(/Đ/g, "D"); // Thay đ/Đ thành d/D
    return str;
  };

  // Loại bỏ khoảng trắng thừa, chuyển thành chữ thường
  text = text.trim().toLowerCase(); // Loại khoảng trắng đầu/cuối và chuyển chữ thường
  text = text.replace(/\s+/g, " "); // Thay nhiều khoảng trắng bằng 1 khoảng trắng
  text = removeVietnameseTones(text); // Loại bỏ dấu tiếng Việt
  return text;
}
