// Chakra imports
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Flex,
  HStack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
  Input,
  Stack,
  Divider,
  Box,
  useToast,
  FormControl,
  FormErrorMessage,
  RadioGroup,
  Radio,
  Spinner,
} from "@chakra-ui/react";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TableRow from "./components/TableRow.jsx";
import { invoiceActions } from "redux/actions";
import { arrColumns } from "constants";
import CustomInputCalendar from "components/CustomInputCalendar";
import { calculateResult, calculateResultEachRow, isNumeric } from "common";
import moment from "moment";
import { useDidUpdateEffect } from "utils/HookUtils";
import {
  purchaseInvoiceDetailRequireFields,
  purchaseInvoiceRequireFields,
} from "constants";

const initPurcharInvoiceDetail = {
  VoucherDetailID: null,
  VoucherID: null,
  ItemID: null,
  Description: null,
  StockID: null,
  DebitAccount: 156,
  CreditAccount: null,
  Unit: null,
  UnitConvert: null,
  Quantity: null,
  QuantityConvert: 0.0,
  UnitPriceOC: null,
  UnitPrice: null,
  UnitPriceConvertOC: 0.0,
  UnitPriceConvert: null,
  AmountOC: null,
  Amount: null,
  DiscountRate: null,
  DiscountAmountOC: null,
  DiscountAmount: null,
  DiscountAccount: null,
  ImportTaxRate: null,
  ImportTaxAmountOC: null,
  ImportTaxAmount: null,
  ImportTaxAccount: null,
  VATAmountOC: null,
  VATAmount: null,
  VATAccount: null,
  InventoryAccount: null,
  COGAccount: null,
  OutwardPriceOC: null,
  OutwardPrice: null,
  OutwardAmountOC: null,
  OutwardAmount: null,
  DeductionDebitAccount: null,
  CustomsUnitPriceOC: null,
  CustomsUnitPrice: null,
  SpecialConsumeTaxRate: null,
  SpecialConsumeTaxAmount: null,
  SpecialConsumeTaxAmountOC: null,
  SpecialConsumeTaxAccount: null,
  FreightAmountOC: null,
  FreightAmount: null,
  AccountingObjectID: null,
  ContractID: null,
  StatisticItemID: null,
  DepartmentID: null,
  ExpiryDate: null,
  LotNo: null,
  VATPaid: null,
  PaymentVoucherID: null,
  SortOrder: null,
  ConvertRate: null,
  ImportTaxExpenseAmount: null,
  ImportTaxExpenseAmountOC: null,
  DiscountAmountAfterTax: null,
  DiscountAmountAfterTaxOC: null,
  ExpenseItemID: null,
  JobID: null,
  OutwardPriceConvertOC: null,
  OutwardPriceConvert: null,
  TransporterID: null,
  AllocationTime: null,
  ExpenseAccount: null,
  EnvironmentTaxRate: null,
  EnvironmentTaxAmountOC: null,
  EnvironmentTaxAmount: null,
  EnvironmentTaxAccount: null,
  ColumnQuantity1: null,
  ColumnQuantity2: null,
  ColumnQuantity3: null,
  ColumnAmount1: null,
  ColumnAmount2: null,
  TurnOverAmountOC: null,
  TurnOverAmount: null,
  AllocationAmount: null,
  InvoiceTypeID: null,
  PurchasePurposeID: null,
  AccountingObjectTaxID: null,
  AccountingObjectTaxName: null,
  AccountingObjectTaxAddress: null,
  AccountingObjectTaxCode: null,
  VATRate: null,
  InvType: null,
  InvDate: null,
  InvSeries: null,
  InvNo: null,
  VATPostedDate: null,
  ColumnText2: null,
  ColumnText1: null,
  FIFOQuantity: null,
  FIFOQuantityConvert: null,
  Select1: null,
  Select2: null,
  ColumnDate1: null,
  ColumnDate2: null,
  DiscountVATRate: null,
  VATDescription: null,
};

const initPurcharInvoice = {
  INVoucherNo: null,
  VoucherType: null,
  AccountingObjectID: null,
  AccountingObjectCode: null,
  AccountingObjectName: null,
  AccountingObjectAddress: null,
  INContactName: null,
  INJournalMemo: null,
  CABAVoucherNo: null,
  AccountingObjectBankAccount: null,
  AccountingObjectBankName: null,
  CABAContactName: null,
  BankAccount: null,
  BankName: null,
  CABAJournalMemo: null,
  DocumentIncluded: null,
  BillReceived: null,
  CurrencyKindID: null,
  ExchangeRate: null,
  PaymentTermID: null,
  DueDate: null,
  EmployeeID: null,
  IsImportPurchase: null,
  SpecialConsumeTax: null,
  TotalAmountOC: null,
  TotalAmount: null,
  TotalImportTaxAmountOC: null,
  TotalImportTaxAmount: null,
  TotalVATAmountOC: null,
  TotalVATAmount: null,
  TotalDiscountAmountOC: null,
  TotalDiscountAmount: null,
  TotalFreightAmountOC: null,
  TotalFreightAmount: null,
  TotalOutwardAmountOC: null,
  TotalOutwardAmount: null,
  IsPosted: null,
  EditVersion: null,
  PostVersion: null,
  IsAttachList: null,
  ListCommonNameInventory: null,
  IsShowUnitConvert: null,
  CreatedBy: null,
  ModifiedBy: null,
  TotalImportTaxExpenseAmountOC: null,
  TotalImportTaxExpenseAmount: null,
  BranchID: null,
  PostedDate: null,
  TotalEnvironmentTaxAmountOC: null,
  TotalEnvironmentTaxAmount: null,
  CompanyTaxCode: null,
  VATDescription: null,
  TotalTurnOverAmountOC: null,
  TotalTurnOverAmount: null,
  TotalSpecialConsumeTaxAmount: null,
  TotalSpecialConsumeTaxAmountOC: null,
  IsNotUpdateOutward: null,
  OriginalVoucherNo: null,
  PUInvoiceID: null,
  VoucherDate: null,
};

function OrderAdd({ location }) {
  const toast = useToast();
  const history = useHistory();
  const dispatch = useDispatch();

  const isApprove = location?.state?.isApprove ?? false;
  const isDetail = location?.state?.isDetail ?? false;
  const record = location?.state?.row;
  const {
    isGettingInvoice,
    invoice,
    products,
    purchasePurposes,
    isCreatingInvoice,
    isUpdatingInvoiceStatus,
    isGettingProducts,
    isGettingPurchasePurposes,
    isGettingStocks,
    isGettingStatistics,
    stocks,
  } = useSelector((state) => state.invoiceReducer);
  const recordsets = (products?.recordsets ?? []).flat(1);
  const purchasePurposeRecordsets = (purchasePurposes?.recordsets ?? []).flat(
    1
  );
  // const [purcharInvoice, setPurcharInvoice] = useState({
  //   AccountingObjectName: '', // company name
  //   AccountingObjectID: '',
  //   AccountingObjectAddress: '', // address
  //   TaxCode: '', // Mã số thuế
  //   ContactName: '', // Người liên hệ
  //   Description: '', // Diễn giải
  //   VoucherDate: '', // Ngày chứng (HD Dau vao)
  //   INVoucherNo: '', // Số phiếu nhập kho, (HD Dau vao)
  //   BankAccount: '', // Tài khoản ngân hàng trả (HD Dau vao)
  //   BankName: '', // Ngân hàng trả (HD Dau vao)
  //   EmployeeID: '', // ID nhân viên (HD Dau ra)
  //   PostedDate: '', // Ngày ghi sổ (HD Dau ra)
  // });

  const [purcharInvoice, setPurcharInvoice] = useState(initPurcharInvoice);
  const [orderType, setOrderType] = useState(-1);
  const [purcharInvoiceDetails, setPurcharInvoiceDetails] = useState([]);
  const [purcharInvoiceDetails2, setPurcharInvoiceDetails2] = useState([]);
  const [isSwaping, setIsSwaping] = useState(false);
  const [tab, setTab] = useState(0);

  // const purcharInvoiceDetails = invoice?.purcharInvoiceDetails ?? [];
  const [fileUpload, setFileUpload] = useState({
    file: "",
    name: "",
  });

  const [invoiceProvider, setInvoiceProvider] = useState({
    label: "BKAV",
    value: "bkav",
  });

  function checkPurcharInvoiceRequireFields(data) {
    const messages = [];
    Object.keys(purchaseInvoiceRequireFields).forEach((key) => {
      if (!data || !data[key] || data[key] === null) {
        messages.push(purchaseInvoiceRequireFields[key]);
      }
    });

    if (messages.length > 0) {
      toast({
        position: "top-right",
        title: "Thông báo",
        render: () => (
          <VStack
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              justifyContent: "flex-start",
            }}
            color="white"
            p={3}
            bg="red.500"
          >
            {messages.map((message) => (
              <span style={{ text: "white", fontSize: 12 }}>
                {message.message} không hợp lệ!
              </span>
            ))}
          </VStack>
        ),
      });
      return false;
    }
    return true;
  }

  function checkPurcharInvoiceDeteailsRequireFields(data) {
    const messages = [];
    Object.keys(purchaseInvoiceDetailRequireFields).forEach((key) => {
      data.forEach((r, i) => {
        if (_.isNil(r[key]) || r[key] === "") {
          messages.push({
            ...purchaseInvoiceDetailRequireFields[key],
            index: i,
          });
        }
      });
    });
    if (messages.length > 0) {
      toast({
        position: "top-right",
        render: () => (
          <VStack
            style={{
              maxHeight: "80vh",
              overflowY: "scroll",
              justifyContent: "flex-start",
            }}
            color="white"
            p={3}
            bg="red.500"
          >
            {messages.map((message) => (
              <span style={{ text: "white", fontSize: 12 }}>
                Dòng {message.index + 1}: {message.message} không hợp lệ!
              </span>
            ))}
          </VStack>
        ),
      });
      return false;
    }
    return true;
  }

  const onSubmit = (isOut = false) => {
    const ret = calculateResult({
      purcharInvoice,
      purcharInvoiceDetails,
    });

    const tmp = _.cloneDeep(ret.purcharInvoiceDetails);

    tmp.forEach((item) => {
      Object.keys(item ?? {}).forEach(function (key) {
        if (
          key === "x1" ||
          key === "x2" ||
          key === "x3" ||
          key === "x4" ||
          key === "x5" ||
          key === "label" ||
          key === "value"
        ) {
          delete item[key];
        }
      });
    });
    const check1 = checkPurcharInvoiceRequireFields(ret.purcharInvoice);
    const check2 = checkPurcharInvoiceDeteailsRequireFields(tmp);
    if (!check1 || !check2) {
      return;
    }

    const body = {
      purcharInvoice: {
        ...ret.purcharInvoice,
        SpecialConsumeTax: "0",
        TotalImportTaxAmountOC: 0,
        TotalImportTaxAmount: 0,
        TotalDiscountAmountOC: 0.0,
        TotalDiscountAmount: 0.0,
        TotalFreightAmountOC: 0.0,
        TotalFreightAmount: 0.0,
        IsPosted: 0,
        IsAttachList: "1",
        IsShowUnitConvert: "0",
        TotalImportTaxExpenseAmountOC: 0.0,
        TotalImportTaxExpenseAmount: 0.0,
        PostedDate: !!ret.purcharInvoice?.PostedDate
          ? moment(ret.purcharInvoice.PostedDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        VoucherDate: !!ret.purcharInvoice?.VoucherDate
          ? moment(ret.purcharInvoice.VoucherDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        DueDate: !!ret.purcharInvoice?.DueDate
          ? moment(ret.purcharInvoice.DueDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        TotalEnvironmentTaxAmountOC: 0.0,
        TotalEnvironmentTaxAmount: 0.0,
        TotalSpecialConsumeTaxAmount: 0.0,
        TotalSpecialConsumeTaxAmountOC: 0.0,
        IsNotUpdateOutward: "0",
      },
      purcharInvoiceDeteails: tmp.map((item) => ({
        ...item,
        QuantityConvert: item?.QuantityConvert ?? 0.0,
        UnitPriceConvertOC: item?.UnitPriceConvertOC ?? 0.0,
        UnitPriceConvert: item?.UnitPriceConvert ?? 0.0,
        AmountOC: item?.AmountOC ?? 0.0,
        Amount: item?.Amount ?? 0.0,
        DiscountRate: item?.DiscountRate ?? 0.0,
        DiscountAmountOC: item?.DiscountAmountOC ?? 0.0,
        DiscountAmount: item?.DiscountAmount ?? 0.0,
        ImportTaxRate: item?.ImportTaxRate ?? 0.0,
        ImportTaxAmountOC: item?.ImportTaxAmountOC ?? 0.0,
        ImportTaxAmount: item?.ImportTaxAmount ?? 0.0,
        Description: item?.Description ?? "",
        VATAmountOC: item?.VATAmountOC ?? 0.0,
        VATAmount: item?.VATAmount ?? 0.0,
        CustomsUnitPriceOC: item?.CustomsUnitPriceOC ?? 0.0,
        CustomsUnitPrice: item?.CustomsUnitPrice ?? 0.0,
        SpecialConsumeTaxRate: item?.SpecialConsumeTaxRate ?? 0.0,
        SpecialConsumeTaxAmount: item?.SpecialConsumeTaxAmount ?? 0.0,
        SpecialConsumeTaxAmountOC: item?.SpecialConsumeTaxAmountOC ?? 0.0,
        FreightAmountOC: item?.FreightAmountOC ?? 0.0,
        FreightAmount: item?.FreightAmount ?? 0.0,
        ImportTaxExpenseAmount: item?.ImportTaxExpenseAmount ?? 0.0,
        ImportTaxExpenseAmountOC: item?.ImportTaxExpenseAmountOC ?? 0.0,
        DiscountAmountAfterTax: item?.DiscountAmountAfterTax ?? 0.0,
        DiscountAmountAfterTaxOC: item?.DiscountAmountAfterTaxOC ?? 0.0,
        ColumnQuantity1: item?.ColumnQuantity1 ?? 0.0,
        ColumnQuantity2: item?.ColumnQuantity2 ?? 0.0,
        ColumnQuantity3: item?.ColumnQuantity3 ?? 0.0,
        ColumnAmount1: item?.ColumnAmount1 ?? 0.0,
        ColumnAmount2: item?.ColumnAmount2 ?? 0.0,
        FIFOQuantity: item?.FIFOQuantity ?? 0.0,
        FIFOQuantityConvert: item?.FIFOQuantityConvert ?? 0.0,
        VATPaid: item?.VATPaid ?? "0",
        AllocationTime: item?.AllocationTime ?? 0,
        EnvironmentTaxRate: item?.EnvironmentTaxRate ?? 0.0,
        EnvironmentTaxAmountOC: item?.EnvironmentTaxAmountOC ?? 0.0,
        EnvironmentTaxAmount: item?.EnvironmentTaxAmount ?? 0.0,
        DiscountVATRate: item?.DiscountVATRate ?? 0.0,
        AllocationAmount: item?.AllocationAmount ?? 0.0,
      })),
    };

    if (isApprove) {
      dispatch(
        invoiceActions.updateSaveInvoiceStatus(
          record.id,
          Object.assign(body, { saveInvoiceStatus: 1 }),
          {
            success: () => {
              if (isOut) {
                setTimeout(() => {
                  history.goBack();
                }, 2000);
              }
              toast({
                position: "top-right",
                render: () => (
                  <Box color="white" p={3} bg="telegram.500">
                    Duyệt invoice thành công
                  </Box>
                ),
              });
            },
            failed: (mess) => {
              toast({
                position: "top-right",
                render: () => (
                  <Box color="white" p={3} bg="red.500">
                    Duyệt invoice lỗi!. Lỗi: {mess}
                  </Box>
                ),
              });
            },
          }
        )
      );
      return;
    }
    dispatch(
      invoiceActions.createInvoice(body, {
        success: () => {
          if (isOut) {
            setTimeout(() => {
              history.goBack();
            }, 2000);
          }
          toast({
            position: "top-right",
            render: () => (
              <Box color="white" p={3} bg="telegram.500">
                Tạo invoice thành công
              </Box>
            ),
          });
        },
        failed: (mess) => {
          toast({
            position: "top-right",
            render: () => (
              <Box color="white" p={3} bg="red.500">
                Tạo invoice lỗi!. Lỗi: {mess}
              </Box>
            ),
          });
        },
      })
    );
  };

  // const handleChangeFile = (e) => {
  //   if (_.isEmpty(e)) return;
  //   const arrNameFile = e.target.files[0].name.split('.');
  //   if (arrNameFile[arrNameFile.length - 1] === 'xml') {
  //     setFileUpload({
  //       name: arrNameFile,
  //       file: e.target.files[0],
  //     });
  //     const body = {
  //       xmlFile: e.target.files[0],
  //       invoiceProvider: invoiceProvider.value,
  //     };
  //     dispatch(
  //       invoiceActions.getInvoice(body, {
  //         success: (data) => {},
  //         failed: (mess) => {
  //           toast({
  //             position: 'top-right',
  //             render: () => (
  //               <Box color="white" p={3} bg="red.500">
  //                 Lấy dữ liệu lỗi!. Lỗi: {mess}
  //               </Box>
  //             ),
  //           });
  //         },
  //       })
  //     );
  //   } else {
  //     toast({
  //       position: 'top-right',
  //       render: () => (
  //         <Box color="white" p={3} bg="orange.500">
  //           Vui lòng chọn đúng định dạng file xml!
  //         </Box>
  //       ),
  //     });
  //   }
  // };

  // const pushPurcharInvoiceDetail = () => {
  //   setPurcharInvoiceDetails((prev) => [...prev, initPurcharInvoiceDetail]);
  // };

  const handleSetPurcharInvoiceDetails = ({
    index = -1,
    name = "",
    value = "",
  }) => {
    if (index === -1) return;

    try {
      if (name === "selectProduct") {
        setPurcharInvoiceDetails([
          ...purcharInvoiceDetails.slice(0, index),
          !value ? initPurcharInvoiceDetail : value,
          ...purcharInvoiceDetails.slice(index + 1),
        ]);
      } else {
        let tmp = _.cloneDeep(purcharInvoiceDetails[index]);
        tmp[name] = value;
        // const Amount =
        //   Number(tmp?.Quantity ?? '0') * Number(tmp?.UnitPrice ?? '0');
        // tmp.Amount = Amount;
        switch (name) {
          case "UnitPrice":
            tmp.UnitPriceOC =
              Number(tmp?.UnitPrice ?? 0) / purcharInvoice.ExchangeRate;
          case "Quantity":
            tmp = calculateResultEachRow(tmp, purcharInvoice.ExchangeRate);
            tmp.VATAmount = (Number(tmp.VATRate ?? 0) * tmp.AmountOC) / 100;
            tmp.VATAmountOC = tmp.VATAmount * purcharInvoice.ExchangeRate;
            break;
          case "VATRate":
            tmp.VATAmount = (Number(value) * tmp.AmountOC) / 100;
            tmp.VATAmountOC = tmp.VATAmount * purcharInvoice.ExchangeRate;
            break;
        }
        setPurcharInvoiceDetails([
          ...purcharInvoiceDetails.slice(0, index),
          tmp,
          ...purcharInvoiceDetails.slice(index + 1),
        ]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (products?.recordsets === undefined && !isGettingProducts) {
      dispatch(
        invoiceActions.getProducts(
          queryString.stringify({
            procName: "Proc_SelectItem",
          })
        )
      );
    }
    if (
      purchasePurposes?.recordsets === undefined &&
      !isGettingPurchasePurposes
    ) {
      dispatch(
        invoiceActions.getPurchasePurposes(
          queryString.stringify({
            procName: "Proc_SelectPurchasePurpose",
          })
        )
      );
    }

    if (stocks?.recordsets === undefined && !isGettingStocks) {
      dispatch(
        invoiceActions.getStocks(
          queryString.stringify({
            procName: "Proc_SelectStock",
          })
        )
      );
    }
  }, [isGettingProducts, isGettingPurchasePurposes, isGettingStocks]);

  useDidUpdateEffect(() => {
    if (
      isGettingInvoice ||
      isGettingProducts ||
      isGettingPurchasePurposes ||
      isGettingStocks ||
      isGettingStatistics
    )
      return;
    if (!invoice?.purcharInvoice?.AccountingObjectCode && !!fileUpload?.name)
      toast({
        position: "top-right",
        render: () => (
          <Box color="white" p={3} bg="red.500">
            Mã NCC không chưa xác định
          </Box>
        ),
      });
    setPurcharInvoice(
      invoice?.purcharInvoice
        ? {
            ...invoice.purcharInvoice,
            // VoucherType: invoice?.VoucherType
            //   ? parseInt(invoice.VoucherType)
            //   : null,
            INJournalMemo: "Mua hàng hoá",
            CABAJournalMemo: "Mua hàng hoá",
            CompanyTaxCode: invoice?.purcharInvoice?.AccountingObjectCode,
          }
        : initPurcharInvoice
    );
    if ([101, 87, 77].includes(invoice?.purcharInvoice?.VoucherType)) {
      setOrderType(1);
    }
    const messsages = [];
    const p = (invoice?.purcharInvoiceDetails ?? []).map((data, index) => {
      // data.Description = null
      const ps = recordsets.filter(
        (record) =>
          (record?.ItemName ?? "").includes(data?.Description) ||
          (data?.Description ?? "").includes(record?.ItemName)
      );

      let product = ps.find((p) => p.ItemName.includes(data.UnitPrice));
      if (!product) {
        product = recordsets.find(
          (record) =>
            (record?.ItemName ?? "").includes(data?.Description) ||
            (data?.Description ?? "").includes(record?.ItemName)
        );
      }

      if (!product) {
        messsages.push(`Lỗi không tìm thấy sản phẩm dòng ${index + 1}`);
        return {
          ...data,
          ItemName: data?.Description ?? "",
          PurchasePurposeID: "68F05655-1AFC-45AB-834A-37FAA500766C",
          PurchasePurposeCode: "1",
        };
      }
      const purchasePurpose = purchasePurposeRecordsets.find(
        (record) => record.PurchasePurposeID === product.PurchasePurposeID
      );
      return {
        ...initPurcharInvoiceDetail,
        ...data,
        ...product,
        label: `(${product?.ItemCode ?? ""})${product?.ItemName ?? ""}`,
        value: product?.ItemID ?? "",
        Description: product?.ItemName ?? "",
        VATAccount: product?.VATAccount ?? "13311",
        PurchasePurposeID: purchasePurpose
          ? purchasePurpose.PurchasePurposeID
          : "68F05655-1AFC-45AB-834A-37FAA500766C",
        PurchasePurposeCode: purchasePurpose
          ? purchasePurpose.PurchasePurposeCode
          : "1",
        OutwardAmount: data.Amount,
        AccountingObjectCode:
          invoice?.purcharInvoice?.AccountingObjectCode ?? null,
        CompanyTaxCode: invoice?.purcharInvoice?.AccountingObjectCode ?? null,
        AccountingObjectAddress:
          invoice?.purcharInvoice?.AccountingObjectAddress ?? null,
        VATDescription: invoice?.purcharInvoice?.VATDescription ?? null,
      };
    });

    if (messsages.length) {
      toast({
        position: "top-right",
        render: () => (
          <Box color="white" p={3} bg="red.500">
            {messsages.map((message, idx) => (
              <p key={idx}>{message}</p>
            ))}
          </Box>
        ),
      });
    }

    setPurcharInvoiceDetails(p);

    if (p.length > 0) {
      const obj = p.reduce(
        (prev, curr) => {
          const VATAmountOC =
            prev.VATAmountOC + (curr.VATRate * curr.AmountOC) / 100;
          const amountOC =
            Number(curr?.UnitPriceOC || 0) * Number(curr?.Quantity || 0);
          const AmountOC = prev.AmountOC + amountOC;
          const OutwardPriceOC =
            prev.OutwardPriceOC + Number(curr?.UnitPriceOC || 0);
          const OutwardAmountOC = prev.OutwardAmountOC + amountOC;
          const OutwardPriceConvertOC = prev.OutwardPriceConvertOC + amountOC;
          const TurnOverAmountOC = prev.TurnOverAmountOC + amountOC;

          return {
            VATAmountOC,
            AmountOC,
            OutwardPriceOC,
            OutwardAmountOC,
            OutwardPriceConvertOC,
            TurnOverAmountOC,
          };
        },
        {
          VATAmountOC: 0,
          AmountOC: 0,
          OutwardPriceOC: 0,
          OutwardAmountOC: 0,
          OutwardPriceConvertOC: 0,
          TurnOverAmountOC: 0,
        }
      );
      const exchangeRate = invoice?.saleInvoice?.ExchangeRate || 1;
      obj.Amount = obj.AmountOC * exchangeRate;
      obj.UnitPriceOC = obj.AmountOC;
      obj.UnitPrice = obj.UnitPriceOC * exchangeRate;
      obj.OutwardPrice = obj.OutwardPriceOC * exchangeRate;
      obj.OutwardAmount = obj.OutwardAmountOC * exchangeRate;
      obj.OutwardPriceConvert = obj.OutwardPriceConvertOC * exchangeRate;
      obj.TurnOverAmount = obj.TurnOverAmountOC * exchangeRate;
      obj.TotalAmountOC = obj.AmountOC;
      obj.TotalAmount = obj.AmountOC * exchangeRate;
      obj.VATAmount = obj.VATAmountOC * exchangeRate;

      const product = recordsets.find(
        (record) =>
          (record?.ItemName ?? "")
            .toLowerCase()
            .includes("DVMN".toLowerCase()) ||
          "Dịch vụ mua ngoài"
            .toLowerCase()
            .includes((record?.ItemName ?? "").toLowerCase()) ||
          (record?.ItemName ?? "").includes("DTDV".toLowerCase()) ||
          "Doanh thu dịch vụ"
            .toLowerCase()
            .includes((record?.ItemName ?? "").toLowerCase())
      );
      const p2 = [
        Object.assign(
          {
            ...p[0],
            Quantity: 1,
            ExpenseItemID: "A3721A41-EE69-4105-8146-7B9F1287C022",
            DebitAccount: 154,
          },
          product
            ? {
                ...product,
                ...obj,
                label: `(${product?.ItemCode ?? ""})${product?.ItemName ?? ""}`,
                value: product?.ItemID ?? "",
                Description: product?.ItemName ?? "",
                VATAccount: product?.VATAccount ?? "13311",
              }
            : {}
        ),
      ];
      setPurcharInvoiceDetails2(p2);
    }
  }, [
    invoice,
    products,
    isGettingInvoice,
    isGettingProducts,
    isGettingPurchasePurposes,
    isGettingStocks,
    isGettingStatistics,
  ]);

  function swapPurcharInvoiceDetails() {
    setIsSwaping((prev) => !prev);
    const tmp = _.cloneDeep(purcharInvoiceDetails);
    setPurcharInvoiceDetails(purcharInvoiceDetails2);
    setPurcharInvoiceDetails2(tmp);
    setTimeout(() => {
      setIsSwaping((prev) => !prev);
    }, 500);
  }

  useEffect(() => {
    if (!!record) {
      dispatch(invoiceActions.setInvoice(record));
    } else {
      dispatch(invoiceActions.setInvoice({}));
    }
  }, [record]);

  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const renderTable = useMemo(() => {
    // if (_.isEmpty(purcharInvoiceDetails))
    //   return (
    //     <IconButton
    //       colorScheme="blue"
    //       onClick={pushPurcharInvoiceDetail}
    //       icon={<AddIcon />}
    //     />
    //   );
    return (
      <Box style={{ overflowX: "scroll", maxHeight: "76vh" }}>
        <Table style={{}} variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {arrColumns[tab].map((item, index) => (
                <Th
                  key={index}
                  borderColor={borderColor}
                  fontSize={item?.headerStyle ?? [10, 10, 12, 12]}
                  px={"5px"}
                  py={"8px"}
                  color="#054169"
                  className={item?.className ?? ""}
                  style={item?.headerStyle ?? {}}
                >
                  <span
                    className={
                      !!purchaseInvoiceDetailRequireFields[item.value]
                        ? "required"
                        : ""
                    }
                  >
                    {item.label}
                  </span>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {!isSwaping
              ? purcharInvoiceDetails.map((item, index, arr) => {
                  return (
                    <TableRow
                      data={item ?? initPurcharInvoiceDetail}
                      isLast={index === arr.length - 1 ? true : false}
                      key={index}
                      indexP={index}
                      arrColumns={arrColumns}
                      tab={tab}
                      AccountingObjectCode={purcharInvoice.AccountingObjectCode}
                      AccountingObjectAddress={
                        purcharInvoice.AccountingObjectAddress
                      }
                      VATDescription={purcharInvoice.VATDescription}
                      isDetail={isDetail}
                      handleSetPurcharInvoiceDetails={
                        handleSetPurcharInvoiceDetails
                      }
                      ExchangeRate={purcharInvoice?.ExchangeRate || 1}
                    />
                  );
                })
              : null}
          </Tbody>
        </Table>
        {/* <IconButton
          onClick={pushPurcharInvoiceDetail}
          mt={3}
          colorScheme="blue"
          icon={<AddIcon />}
        /> */}
      </Box>
    );
  }, [tab, isSwaping, purcharInvoiceDetails]);
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <form onSubmit={onSubmit}>
          <Card pb="0px">
            <CardHeader p="6px 0px 22px 0px">
              <VStack mb={3}>
                <HStack width={"100%"} justifyContent={"space-between"}>
                  <Text
                    w={"100%"}
                    fontSize={16}
                    color={"#054169"}
                    fontWeight="bold"
                  >
                    TẬP TIN HÓA ĐƠN
                  </Text>
                  <HStack>
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      fontSize={[10, 12, 14, 16]}
                      colorScheme="gray"
                      textTransform={"uppercase"}
                    >
                      Quay lại
                    </Button>
                    {!isDetail && (
                      <Button
                        disabled={isCreatingInvoice || isUpdatingInvoiceStatus}
                        isLoading={isCreatingInvoice || isUpdatingInvoiceStatus}
                        onClick={() => onSubmit(true)}
                        fontSize={[10, 12, 14, 16]}
                        colorScheme="orange"
                        textTransform={"uppercase"}
                      >
                        Lưu và thoát
                      </Button>
                    )}
                    {!isApprove && !isDetail && (
                      <Button
                        disabled={isCreatingInvoice || isUpdatingInvoiceStatus}
                        isLoading={isCreatingInvoice || isUpdatingInvoiceStatus}
                        onClick={() => onSubmit(false)}
                        fontSize={[10, 12, 14, 16]}
                        colorScheme="orange"
                        textTransform={"uppercase"}
                      >
                        Lưu và tiếp tục
                      </Button>
                    )}
                  </HStack>
                </HStack>
                {/* <Stack
                  justifyContent={[
                    'flex-start',
                    'flex-start',
                    'center',
                    'center',
                  ]}
                  flexDir={['column', 'column', 'column', 'row']}
                  w={'100%'}
                >
                  <HStack
                    mx={[0, 0, 0, 3]}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                  >
                    <Text
                      color={'#054169'}
                      fontSize={14}
                      fontWeight={'400'}
                      as="span"
                    >
                      Chọn nhà cung cấp hóa đơn
                    </Text>
                    <Box minW={250}>
                      <Select
                        placeholder="Lựa chọn"
                        value={isApprove ? null : invoiceProvider}
                        isClearable={true}
                        onChange={(e) => {
                          setInvoiceProvider(e);
                        }}
                        isDisabled={isApprove || isDetail}
                        options={[
                          { label: 'BKAV', value: 'Bkav' },
                          { label: 'EASYVOICE', value: 'EasyVoice' },
                          { label: 'MEINVOICE', value: 'MeinVoice' },
                          { label: 'VNPT', value: 'Vnpt' },
                          { label: 'VIETTEL', value: 'Viettel' },
                        ]}
                        onInputChange={(value) => {
                          // setRoleSearch(value);
                        }}
                        onFocus={() => {}}
                      />
                    </Box>
                  </HStack>

                  <HStack
                    mx={3}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                  >
                    <Text
                      color={'#054169'}
                      fontSize={14}
                      fontWeight={'400'}
                      as="span"
                    >
                      Upload tập tin hóa đơn{'   '}
                      <Text
                        opacity={0.7}
                        color={'#1E1E1E'}
                        fontSize={14}
                        fontWeight={'400'}
                        as="span"
                      >
                        {fileUpload.name === ''
                          ? 'Chưa có tập tin được chọn'
                          : fileUpload.name}
                      </Text>
                    </Text>
                    {!isGettingInvoices && (
                      <input
                        className="custom-file-input-css"
                        id="customFileLang"
                        lang="en"
                        type="file"
                        accept=".xml"
                        disabled={isApprove || isDetail}
                        onChange={handleChangeFile}
                        style={{ display: 'none' }}
                      />
                    )}

                    <label
                      style={{
                        background: '#3182ce',
                        borderRadius: 6,
                        fontSize: 12,
                        color: 'white',
                        padding: '5px 40px',
                        lineHeight: 1.2,
                        fontWeight: '700',
                      }}
                      htmlFor="customFileLang"
                    >
                      Chọn file (XML)
                    </label>
                  </HStack>
                </Stack> */}
              </VStack>
              <Divider />
            </CardHeader>
            <CardBody mb={5}>
              <Stack flexDir={["column", "column", "column", "row"]}>
                <VStack
                  pr={[0, 0, 0, 3]}
                  borderRight={["none", "none", "none", "1px solid #ccc"]}
                  justifyContent={"space-between"}
                  spacing={3}
                  flex={7}
                >
                  <Text
                    w={"100%"}
                    fontSize={16}
                    color={"#054169"}
                    fontWeight="bold"
                  >
                    THÔNG TIN NHÀ CUNG CẤP
                  </Text>

                  <Stack
                    alignItems={["", "", "center", "center"]}
                    flexDir={["column", "column", "row", "row"]}
                    w={"100%"}
                  >
                    <Box w={170}>Nhà cung cấp</Box>
                    <Box px={[0, 0, 1, 1]}>
                      <FormControl
                        isInvalid={
                          (purcharInvoice?.AccountingObjectCode ?? "") === "" &&
                          !!fileUpload?.name
                        }
                      >
                        <Input
                          maxW={150}
                          rounded={6}
                          placeholder="Mã NCC"
                          size={"md"}
                          id={"AccountingObjectCode"}
                          name={"AccountingObjectCode"}
                          value={purcharInvoice?.AccountingObjectCode ?? ""}
                          disabled={isDetail}
                          onChange={(e) => {
                            setPurcharInvoice({
                              ...purcharInvoice,
                              AccountingObjectCode: e.target.value,
                            });
                          }}
                          // disabled={true}
                        />
                        <FormErrorMessage>
                          {(purcharInvoice?.AccountingObjectCode ?? "") ===
                            "" &&
                            !!fileUpload?.name &&
                            "Mã NCC không được để trống!"}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box px={[0, 0, 1, 1]} flex={1}>
                      <Input
                        rounded={6}
                        placeholder="Nhập tên NCC"
                        size={"md"}
                        id={"AccountingObjectName"}
                        name={"AccountingObjectName"}
                        value={purcharInvoice?.AccountingObjectName ?? ""}
                        disabled={isDetail}
                        onChange={(e) => {
                          setPurcharInvoice({
                            ...purcharInvoice,
                            AccountingObjectName: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={["", "", "center", "center"]}
                    flexDir={["column", "column", "row", "row"]}
                    w={"100%"}
                  >
                    <Box w={170}>Địa chỉ</Box>
                    <Box px={[0, 0, 1, 1]} flex={1}>
                      <Input
                        rounded={6}
                        placeholder="Nhập địa chỉ"
                        size={"md"}
                        id={"AccountingObjectAddress"}
                        name={"AccountingObjectAddress"}
                        value={purcharInvoice?.AccountingObjectAddress ?? ""}
                        disabled={isDetail}
                        onChange={(e) => {
                          setPurcharInvoice({
                            ...purcharInvoice,
                            AccountingObjectAddress: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={["", "", "center", "center"]}
                    flexDir={["column", "column", "row", "row"]}
                    w={"100%"}
                  >
                    <Box w={170}>Mã số thuế</Box>
                    <Box px={[0, 0, 1, 1]}>
                      <Input
                        maxW={200}
                        rounded={6}
                        placeholder="Nhập mã số thuế"
                        size="md"
                        value={purcharInvoice?.CompanyTaxCode ?? ""}
                        disabled={isDetail}
                        onChange={(e) => {
                          if (!isNumeric(e.target.value)) return;
                          setPurcharInvoice({
                            ...purcharInvoice,
                            CompanyTaxCode: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box
                      pr={2}
                      w={170}
                      textAlign={["left", "left", "right", "right"]}
                    >
                      Người liên hệ
                    </Box>
                    <Box flex={1}>
                      <Input
                        rounded={6}
                        placeholder="Nhập người liên hệ"
                        size={"md"}
                        value={purcharInvoice?.INContactName ?? ""}
                        disabled={isDetail}
                        onChange={(e) => {
                          setPurcharInvoice({
                            ...purcharInvoice,
                            INContactName: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={["", "", "center", "center"]}
                    flexDir={["column", "column", "row", "row"]}
                    w={"100%"}
                  >
                    <Box w={170}>Diễn giải</Box>
                    <Box px={[0, 0, 1, 1]} flex={1}>
                      <Input
                        rounded={6}
                        placeholder="Nhập diễn giải"
                        size={"md"}
                        value={purcharInvoice?.INJournalMemo ?? ""}
                        disabled={isDetail}
                        onChange={(e) => {
                          setPurcharInvoice({
                            ...purcharInvoice,
                            INJournalMemo: e.target.value,
                            CABAJournalMemo: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={[
                      "flex-start",
                      "flex-start",
                      "center",
                      "center",
                    ]}
                    flexDir={["column", "column", "row", "row"]}
                    w={"100%"}
                  >
                    <Box w={170}>Nhân viên mua hàng</Box>
                    <Box px={[0, 0, 1, 1]}>
                      <Input
                        maxW={200}
                        rounded={6}
                        placeholder="Nhập nhân viên mua"
                        size="md"
                        value={purcharInvoice?.EmployeeID ?? ""}
                        disabled={isDetail}
                        onChange={(e) => {
                          setPurcharInvoice({
                            ...purcharInvoice,
                            EmployeeID: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Stack
                      px={[0, 0, 1, 1]}
                      alignItems={[
                        "flex-start",
                        "flex-start",
                        "center",
                        "center",
                      ]}
                      flexDir={["column", "column", "row", "row"]}
                      flex={1}
                    >
                      <Box
                        pr={2}
                        w={170}
                        textAlign={["left", "left", "right", "right"]}
                      >
                        Kèm theo
                      </Box>
                      <HStack>
                        <Input
                          value={purcharInvoice?.DocumentIncluded ?? ""}
                          disabled={isDetail}
                          onChange={(e) => {
                            setPurcharInvoice({
                              ...purcharInvoice,
                              DocumentIncluded: e.target.value,
                            });
                          }}
                          rounded={6}
                          placeholder="Nhập chứng từ gốc"
                          size={"md"}
                        />
                        <Text pl={2} as={"span"} w={200}>
                          chứng từ gốc
                        </Text>
                      </HStack>
                    </Stack>
                  </Stack>
                  <Stack
                    alignItems={["", "", "center", "center"]}
                    flexDir={["column", "column", "row", "row"]}
                    w={"100%"}
                  >
                    <Box w={170}>
                      <span className="required">Loại hoá đơn</span>
                    </Box>
                    <Box px={[0, 0, 1, 1]}>
                      <RadioGroup
                        onChange={(e) => {
                          if (isDetail) return;
                          setOrderType(parseInt(e));
                          if (parseInt(e) === -1) {
                            setPurcharInvoice((prev) => ({
                              ...prev,
                              VoucherType: 100,
                            }));
                          } else {
                            setPurcharInvoice((prev) => ({
                              ...prev,
                              VoucherType: 101,
                            }));
                          }
                          swapPurcharInvoiceDetails();
                        }}
                        value={orderType}
                      >
                        <HStack spacing={5} direction="row">
                          <Radio colorScheme="blue" value={-1}>
                            Hàng hoá
                          </Radio>
                          <Radio colorScheme="blue" value={1}>
                            Dịch vụ
                          </Radio>
                        </HStack>
                      </RadioGroup>
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={["", "", "center", "center"]}
                    flexDir={["column", "column", "row", "row"]}
                    w={"100%"}
                  >
                    <Box w={170}>
                      <span className="required"> Hình thức thanh toán</span>
                    </Box>
                    <Box px={[0, 0, 1, 1]}>
                      <RadioGroup
                        onChange={(e) => {
                          if (isDetail) return;
                          setPurcharInvoice((prev) => ({
                            ...prev,
                            VoucherType: parseInt(e),
                          }));
                        }}
                        value={purcharInvoice.VoucherType}
                      >
                        {orderType === -1 ? (
                          <HStack spacing={5} direction="row">
                            <Radio colorScheme="blue" value={100}>
                              Chưa thanh toán
                            </Radio>
                            <Radio colorScheme="blue" value={71}>
                              Tiền mặt
                            </Radio>
                            <Radio colorScheme="blue" value={81}>
                              Chuyển khoản
                            </Radio>
                          </HStack>
                        ) : (
                          <HStack spacing={5} direction="row">
                            <Radio colorScheme="blue" value={101}>
                              Chưa thanh toán
                            </Radio>
                            <Radio colorScheme="blue" value={77}>
                              Tiền mặt
                            </Radio>
                            <Radio colorScheme="blue" value={87}>
                              Chuyển khoản
                            </Radio>
                          </HStack>
                        )}
                      </RadioGroup>
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={["", "", "center", "center"]}
                    flexDir={["column", "column", "row", "row"]}
                    w={"100%"}
                  >
                    <Box w={170}>TK trả tiền</Box>
                    <Box px={[0, 0, 1, 1]}>
                      <Input
                        maxW={150}
                        rounded={6}
                        placeholder="Nhập số tài khoản trả"
                        size="md"
                        value={purcharInvoice?.BankAccount ?? ""}
                        disabled={isDetail}
                        onChange={(e) => {
                          if (!isNumeric(e.target.value)) return;
                          setPurcharInvoice({
                            ...purcharInvoice,
                            BankAccount: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box px={[0, 0, 1, 1]} flex={1}>
                      <Input
                        rounded={6}
                        placeholder="Nhập tên tài khoản trả"
                        size={"md"}
                        value={purcharInvoice?.BankName ?? ""}
                        disabled={isDetail}
                        onChange={(e) => {
                          setPurcharInvoice({
                            ...purcharInvoice,
                            BankName: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={["", "", "center", "center"]}
                    flexDir={["column", "column", "row", "row"]}
                    w={"100%"}
                  >
                    <Box w={170}>TK nhận tiền</Box>
                    <Box px={[0, 0, 1, 1]}>
                      <Input
                        value={
                          purcharInvoice?.AccountingObjectBankAccount ?? ""
                        }
                        onChange={(e) => {
                          if (!isNumeric(e.target.value)) return;
                          setPurcharInvoice({
                            ...purcharInvoice,
                            AccountingObjectBankAccount: e.target.value,
                          });
                        }}
                        maxW={150}
                        rounded={6}
                        disabled={isDetail}
                        placeholder="Nhập số tài khoản nhận"
                        size="md"
                      />
                    </Box>
                    <Box px={[0, 0, 1, 1]} flex={1}>
                      <Input
                        value={purcharInvoice?.AccountingObjectBankName ?? ""}
                        disabled={isDetail}
                        onChange={(e) => {
                          setPurcharInvoice({
                            ...purcharInvoice,
                            AccountingObjectBankName: e.target.value,
                          });
                        }}
                        rounded={6}
                        placeholder="Nhập tên tài khoản nhận"
                        size={"md"}
                      />
                    </Box>
                  </Stack>
                </VStack>

                <Box pl={[0, 0, 0, 3]} flex={3}>
                  <Text
                    textTransform={"uppercase"}
                    w={"100%"}
                    fontSize={16}
                    color={"#054169"}
                    fontWeight="bold"
                  >
                    chứng từ
                  </Text>
                  <VStack>
                    <HStack w={"100%"} flex={1}>
                      <Box flex={2}>
                        <span className="required">Ngày ghi sổ</span>
                      </Box>
                      <Box flex={3}>
                        <DatePicker
                          customInput={<CustomInputCalendar />}
                          wrapperClassName="icon-calendar"
                          dateFormat={"dd/MM/yyyy"}
                          disabled={isDetail}
                          selected={
                            !!purcharInvoice?.PostedDate
                              ? new Date(purcharInvoice.PostedDate)
                              : null
                          }
                          onChange={(date) => {
                            setPurcharInvoice({
                              ...purcharInvoice,
                              PostedDate: date,
                            });
                          }}
                        />
                      </Box>
                    </HStack>
                    <HStack w={"100%"} flex={1}>
                      <Box flex={2}>Ngày chứng từ</Box>
                      <Box flex={3}>
                        <DatePicker
                          customInput={<CustomInputCalendar />}
                          wrapperClassName="icon-calendar"
                          dateFormat={"dd/MM/yyyy"}
                          disabled={isDetail}
                          selected={
                            !!purcharInvoice?.VoucherDate
                              ? new Date(purcharInvoice.VoucherDate)
                              : null
                          }
                          onChange={(date) => {
                            setPurcharInvoice({
                              ...purcharInvoice,
                              VoucherDate: date,
                            });
                          }}
                        />
                      </Box>
                    </HStack>
                    <HStack w={"100%"} flex={1}>
                      <Box flex={2}>Số phiếu nhập</Box>
                      <Box flex={3}>
                        <Input
                          rounded={6}
                          placeholder="Nhập số phiếu nhập"
                          size={"md"}
                          value={purcharInvoice?.INVoucherNo ?? ""}
                          disabled={isDetail}
                          onChange={(e) => {
                            setPurcharInvoice({
                              ...purcharInvoice,
                              INVoucherNo: e.target.value,
                            });
                          }}
                        />
                      </Box>
                    </HStack>
                    <HStack w={"100%"} flex={1}>
                      <Box flex={2}>Hạn thanh toán</Box>
                      <Box flex={3}>
                        <DatePicker
                          customInput={<CustomInputCalendar />}
                          wrapperClassName="icon-calendar"
                          dateFormat={"dd/MM/yyyy"}
                          disabled={isDetail}
                          selected={
                            !!purcharInvoice?.DueDate
                              ? new Date(purcharInvoice.DueDate)
                              : null
                          }
                          onChange={(date) => {
                            setPurcharInvoice({
                              ...purcharInvoice,
                              DueDate: date,
                            });
                          }}
                        />
                      </Box>
                    </HStack>
                    <HStack w={"100%"} flex={1}>
                      <Box flex={2}>PC/UNC</Box>
                      <Box flex={3}>
                        <Input
                          rounded={6}
                          placeholder="Nhập số phiếu PC/UNC"
                          size={"md"}
                          value={purcharInvoice?.CABAVoucherNo ?? ""}
                          disabled={isDetail}
                          onChange={(e) => {
                            setPurcharInvoice({
                              ...purcharInvoice,
                              CABAVoucherNo: e.target.value,
                            });
                          }}
                        />
                      </Box>
                    </HStack>
                  </VStack>
                </Box>
              </Stack>
              <HStack mb={2} alignItems={"flex-end"}>
                <Box
                  style={{
                    padding: "8px 12px",
                    color: "#054169",
                    fontWeight: 500,
                    borderBottom:
                      tab === 0 ? "2px solid #F07504" : "2px solid #666666",
                  }}
                  onClick={() => setTab(0)}
                  fontSize={[12, 12, 16, 16]}
                  textTransform={"uppercase"}
                >
                  tiền hàng
                </Box>
                <Box
                  style={{
                    padding: "8px 12px",
                    color: "#054169",
                    fontWeight: 500,
                    margin: 0,
                    borderBottom:
                      tab === 1 ? "2px solid #F07504" : "2px solid #666666",
                  }}
                  onClick={() => setTab(1)}
                  fontSize={[12, 12, 16, 16]}
                  textTransform={"uppercase"}
                >
                  hóa đơn
                </Box>
                <Box
                  style={{
                    padding: "8px 12px",
                    color: "#054169",
                    fontWeight: 500,
                    margin: 0,
                    borderBottom:
                      tab === 2 ? "2px solid #F07504" : "2px solid #666666",
                  }}
                  onClick={() => setTab(2)}
                  fontSize={[12, 12, 16, 16]}
                  textTransform={"uppercase"}
                >
                  chi phí
                </Box>
                <Box
                  style={{
                    padding: "8px 12px",
                    color: "#054169",
                    fontWeight: 500,
                    margin: 0,
                    borderBottom:
                      tab === 3 ? "2px solid #F07504" : "2px solid #666666",
                  }}
                  onClick={() => setTab(3)}
                  fontSize={[12, 12, 16, 16]}
                  textTransform={"uppercase"}
                >
                  chiết khấu
                </Box>
                <Box
                  flex={1}
                  style={{
                    borderBottom: "2px solid #666666",
                    margin: 0,
                  }}
                ></Box>
              </HStack>
              {!isGettingInvoice &&
                !isGettingProducts &&
                !isGettingPurchasePurposes &&
                !isGettingStocks &&
                !isGettingStatistics && <Box>{renderTable}</Box>}
              {/* <Tabs
              onChange={(value) => {
                if (value === tab) return;
                setTab((__) => value);
              }}
              mt={3}
            >
              <TabList>
                <Tab fontSize={[12, 12, 16, 16]} textTransform={'uppercase'}>
                  tiền hàng
                </Tab>
                <Tab fontSize={[12, 12, 16, 16]} textTransform={'uppercase'}>
                  hóa đơn
                </Tab>
                <Tab fontSize={[12, 12, 16, 16]} textTransform={'uppercase'}>
                  chi phí
                </Tab>
                <Tab fontSize={[12, 12, 16, 16]} textTransform={'uppercase'}>
                  chiết khấu
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel overflowX={'auto'}>{renderTable}</TabPanel>
                <TabPanel overflowX={'auto'}>{renderTable}</TabPanel>
                <TabPanel overflowX={'auto'}>{renderTable}</TabPanel>
                <TabPanel overflowX={'auto'}>{renderTable}</TabPanel>
              </TabPanels>
            </Tabs> */}
            </CardBody>
          </Card>
        </form>
      </Flex>
      {(isGettingInvoice ||
        isGettingProducts ||
        isGettingPurchasePurposes ||
        isCreatingInvoice ||
        isUpdatingInvoiceStatus) && (
        <div
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100vh",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner colorScheme="blue" size="xl" />
        </div>
      )}
    </div>
  );
}

export default OrderAdd;
