// Chakra imports
import {
  Tr,
  Td,
  useColorModeValue,
  Input,
  // useToast,
  // Box,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
// Custom components
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CustomInputCalendar from "components/CustomInputCalendar";
import { calculateResultSellEachRow, isNumeric } from "common";
const initSaleInvoiceDetail = {
  VoucherID: null,
  ItemID: null,
  Description: "", // diễn giải nvarchar(255),
  StockID: "B19B5F26-4A09-45D8-8345-8B458B0F7994", // kho vật tư uniqueidentifier,
  StockAccountNumber: "156",
  DebitAccount: null, // TK phải thu/TK Nợ nvarchar(20),
  CreditAccount: null, // TK doanh thu/TK Có nvarchar(20),
  Unit: null, // đơn vị tính nvarchar(50),
  UnitConvert: null, // đơn vị tính chuển đổi nvarchar(50),
  OrderQuantity: 0, // decimal(22, 8),
  Quantity: 0, // số lượng decimal(22, 8),
  QuantityConvert: 0, // số lượng chuyển đổidecimal(22, 8),
  ActDiffQuantity: 0, // decimal(22, 8),
  ProductQuantity: 0, // decimal(22, 8),
  UnitPriceOC: 0, // đơn giá money,
  UnitPrice: 0, // đơn giá quy đổimoney,
  UnitPriceConvertOC: 0, // đơn giá chuyển đổimoney,
  UnitPriceConvert: 0, // đơn giá chuyển đổi quy đổi money,
  AmountOC: 0, // thành tiền money,
  Amount: 0, // thành tiền quy đổi money,
  DiscountRate: 0, // tỉ lệ chiết khấu decimal(22, 8),
  DiscountAmountOC: 0, // tiền chiết khấu money,
  DiscountAmount: 0, // tiền chiết khấu quy đổi money,
  DiscountAccount: null, // tài khoản chiết khấu nvarchar(20),
  VATAmountOC: 0, // tiền thuế money,
  VATAmount: 0, // tiền thuế chuyển đổi money,
  VATAccount: "13311", // tài khoản thuế nvarchar(20),
  InventoryAccount: null, // tk kho nvarchar(20),
  COGAccount: null, // tk giá vốn (nợ) nvarchar(20),
  OutwardPrice: 0, // đơn giá vốn money,
  OutwardAmount: 0, // tiền vốn quy đổi money,
  ConfrontingVoucherID: null, // VoucherID của chứng từ nhập đối trừ uniqueidentifier,
  ExpiryDate: null, // hạn dùng date,
  LotNo: null, // số lô nvarchar(255),
  Warranty: null, // hạn bảo hành nvarchar(255),
  AccountingObjectID: null, // đối tượng uniqueidentifier,
  ContractID: null, // hợp đồng uniqueidentifier,
  StatisticItemID: null, // mã thống kê uniqueidentifier,
  SortOrder: null, // Thứ tự sắp xếp các dòng chi tiếtint,
  EnvironmentTaxRate: 0, // decimal(18, 8),
  EnvironmentTaxAmountOC: 0, // money,
  EnvironmentTaxAmount: 0, // money,
  EnvironmentTaxAccount: null, // nvarchar(20),
  ResourcesTaxAmountOC: 0, // thu phí tài nguyên money,
  ResourcesTaxAmount: 0, // thuế phí tìa nguyên chuyển đổi money,
  ResourcesTaxAccount: null, // nvarchar(20),
  EnvironmentForestAmountOC: 0, // money,
  EnvironmentForestAmount: 0, // money,
  EnvironmentForestAccount: null, // nvarchar(20),
  OtherExpenseAmountOC: 0, // money,
  OtherExpenseAmount: 0, // money,
  OtherExpenseAccount: null, // nvarchar(20),
  SpecialConsumeTaxRate: 0, // decimal(22, 8),
  SpecialConsumeTaxAmountOC: 0, // money,
  SpecialConsumeTaxAmount: 0, // money,
  SpecialConsumeUnitPriceOC: 0, // money,
  SpecialConsumeUnitPrice: 0, // money,
  ConvertRate: 1, // tỉ lệ chuyển đổi  decimal(22, 8),
  UnitPriceAfterTaxOC: 0, // money,
  UnitPriceAfterTax: 0, // money,
  AmountAfterTaxOC: 0, // money,
  AmountAfterTax: 0, // money,
  DiscountAmountAfterTax: 0, // decimal(22, 8),
  DiscountAmountAfterTaxOC: 0, // decimal(22, 8),
  DepartmentID: null, // uniqueidentifier,
  CreditAccountingObjectID: null, // uniqueidentifier,
  ConfrontingVoucherDetailID: null, // Chi tiết chứng từ lien quan uniqueidentifier,
  ContractVoucherDetailID: null, // uniqueidentifier,
  JobID: null, // đối tượng tập hợp chi phí uniqueidentifier,
  ExpenseItemID: null, // khoản mục chi phí uniqueidentifier,
  SABonusPolicyDetailID: null, // uniqueidentifier,
  SAPolicyPriceDetailID: null, // uniqueidentifier,
  OutwardPriceConvert: 0, // đơn giá vốn chuyển đổi money,
  TransporterID: null, // người vận chuyển uniqueidentifier,
  ExportTaxRate: 0, // % thuế xk decimal(22, 8),
  ExportTaxAmountOC: 0, // tiền thuế xk money,
  ExportTaxAmount: 0, // tiền thuế xk chuyển đổi money,
  ExportTaxAccount: null, // tk thuế xk nvarchar(20),
  ColumnQuantity1: 0, // decimal(22, 8),
  ColumnQuantity2: 0, // decimal(22, 8),
  ColumnQuantity3: 0, // decimal(22, 8),
  ColumnAmount1: 0, // money,
  ColumnAmount2: 0, // money,
  PurchasePriceOC: 0, // đơn giá mua money,
  PurchasePrice: 0, // đơn giá mua chuyển đổi money,
  ColumnDate1: null, // date,
  ColumnDate2: null, // date,
  VATRate: 0, // decimal(18, 8),
  DiscountVATRate: 0, // decimal(18, 8),
  ColumnText1: null, // nvarchar(255),
  ColumnText2: null, // nvarchar(255),
  Select1: null, // uniqueidentifier,
  Select2: null, // uniqueidentifier,
  FromDate: null, // null date,
  Todate: null, // null date,
  VoucherDetailID: null, // id sale deitail uniqueidentifier OUTPUT
};

const TableRow = ({
  data,
  isLast,
  arrColumns = [],
  tab = 0,
  handleSetSaleInvoiceDetails,
  indexP,
  AccountingObjectCode,
  AccountingObjectAddress,
  VATDescription,
  isDetail,
  ExchangeRate,
}) => {
  // const toast = useToast();
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [productValue, setProductValue] = useState(null);
  const [purchasePurposeValue, setPurchasePurposeValue] = useState(null);
  const [statisticValue, setStatisticValue] = useState(null);
  const [stockValue, setStockValue] = useState(null);
  const [productSearch, setProductSearch] = useState("");
  const [isSetProduct, setIsSetProduct] = useState(true);
  const [productOptions, setProductOptions] = useState([]);

  const {
    products,
    purchasePurposes,
    invoice,
    stocks,
    statistics,
  } = useSelector((state) => state.invoiceReducer);
  const recordsets = (products?.recordsets ?? []).flat(1);
  const purchasePurposeRecordsets = (purchasePurposes?.recordsets ?? []).flat(
    1
  );
  const stockRecordsets = (stocks?.recordsets ?? []).flat(1);
  const statisticsRecordsets = (statistics?.recordsets ?? []).flat(1);
  useEffect(() => {
    if (!stockValue) {
      const stock = _.flattenDeep(stocks?.recordsets || []).find(
        (recordset) => data.StockID === recordset.StockID
      );

      setStockValue(
        !stock
          ? null
          : {
              label: `(${stock.StockCode})${stock.StockName}`,
              value: stock.StockID,
            }
      );
    }
    if (!statisticValue) {
      const statistic = _.flattenDeep(statistics?.recordsets || []).find(
        (recordset) => data.StatisticItemID === recordset.StatisticItemID
      );

      setStatisticValue(
        !statistic
          ? null
          : {
              label: `(${statistic.StatisticItemCode})${statistic.StatisticItemName}`,
              value: statistic.StatisticItemID,
            }
      );
    }
    if (!purchasePurposeValue) {
      setPurchasePurposeValue({
        label: data.PurchasePurposeCode,
        value: data.PurchasePurposeID,
      });
    }
    if (!productValue) {
      setIsSetProduct(false);
      if (!invoice?.saleInvoiceDetails[indexP]?.ItemID && !data?.ItemID) {
        // toast({
        //   position: "top-right",
        //   render: () => (
        //     <Box color="white" p={3} bg="red.500">
        //       Lỗi không tìm thấy sản phẩm dòng {indexP + 1}
        //     </Box>
        //   ),
        // });
        return;
      }
      if (!!data?.ItemID) {
        setProductValue({
          label: `(${data?.ItemCode ?? ""})${data?.ItemName}`,
          value: data?.ItemID ?? "",
        });
      } else if (!!invoice?.saleInvoiceDetails[indexP]?.ItemID) {
        setProductValue({
          label: `(${invoice?.saleInvoiceDetails[indexP]?.ItemCode ?? ""})${
            invoice?.saleInvoiceDetails[indexP]?.ItemName ?? ""
          }`,
          value: invoice?.saleInvoiceDetails[indexP]?.ItemID ?? "",
        });
      }
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (productSearch === "") {
        setProductOptions(recordsets.slice(0, 20));
      } else {
        setProductOptions(
          recordsets
            .filter(
              (item) =>
                productSearch === "" ||
                (item?.ItemCode ?? "").includes(productSearch) ||
                (item?.ItemNameNormal ?? "").includes(
                  normalizeVietnameseText(productSearch)
                )
            )
            .slice(0, 20)
        );
      }
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [productSearch]);

  return (
    <Tr>
      <Td
        key={-1}
        borderColor={borderColor}
        px={"5px"}
        py={"8px"}
        borderBottom={isLast ? "none" : null}

        // style={item?.style ?? {}}
      >
        <FormControl
          isInvalid={
            (productValue?.value ?? "") === "" && !isDetail && !isSetProduct
          }
        >
          <Select
            className={
              (productValue?.value ?? "") === "" && !isDetail && !isSetProduct
                ? "react-select-error"
                : ""
            }
            placeholder="Lựa chọn"
            value={productValue}
            menuPosition="fixed"
            isClearable={true}
            isDisabled={isDetail}
            onChange={(e) => {
              setProductValue(e);
              setPurchasePurposeValue({
                label: "1",
                value: "68F05655-1AFC-45AB-834A-37FAA500766C",
              });
              handleSetSaleInvoiceDetails({
                index: indexP,
                name: "selectProduct",
                value: e,
              });
            }}
            options={productOptions.map((item) => {
              const outwardAmount =
                Number(item.SalePrice || 0) * 0;
              return {
                ...calculateResultSellEachRow(
                  { ...initSaleInvoiceDetail, ...item, Quantity: 0 },
                  ExchangeRate
                ),
                OutwardAmountOC: outwardAmount / ExchangeRate,
                OutwardAmount: outwardAmount,
                UnitPrice: item.SalePrice,
                UnitPriceOC: item.SalePrice / ExchangeRate,
                label: `(${item?.ItemCode ?? ""})${item?.ItemName ?? ""}`,
                value: item?.ItemID ?? "",
                Description: item?.ItemName ?? "",
                VATAccount: item?.VATAccount ?? "13311",
                PurchasePurposeID: "68F05655-1AFC-45AB-834A-37FAA500766C",
                PurchasePurposeCode: "1",
                AccountingObjectCode,
                CompanyTaxCode: AccountingObjectCode,
                AccountingObjectAddress,
                VATDescription,
              };
            })}
            onInputChange={(value) => {
              setProductSearch(value);
            }}
          />

          <FormErrorMessage>
            {(productValue?.value ?? "") === "" &&
              !isDetail &&
              !isSetProduct &&
              "Sản phẩm không được để trống!"}
          </FormErrorMessage>
        </FormControl>
      </Td>
      {arrColumns[tab].slice(1).map((item, index) => {
        // if (index === 0)
        //   return (

        //   );
        return (
          <Td
            key={index}
            borderColor={borderColor}
            px={"5px"}
            py={"8px"}
            borderBottom={isLast ? "none" : null}
            style={item?.style ?? {}}
          >
            {item?.type === "objectId" &&
            item.value === "PurchasePurposeCode" ? (
              <Select
                placeholder="Lựa chọn"
                value={purchasePurposeValue}
                menuPosition="fixed"
                isClearable={true}
                onChange={(e) => {
                  setPurchasePurposeValue(e);
                  handleSetSaleInvoiceDetails({
                    index: indexP,
                    name: item.value,
                    value: e.value,
                  });
                }}
                options={purchasePurposeRecordsets.map((item) => {
                  return {
                    label: item.PurchasePurposeCode,
                    value: item.PurchasePurposeId,
                  };
                })}
              />
            ) : item?.type === "objectId" && item.value === "StockID" ? (
              <Select
                placeholder="Lựa chọn"
                value={stockValue}
                menuPosition="fixed"
                isClearable={true}
                onChange={(e) => {
                  setStockValue(e);
                  handleSetSaleInvoiceDetails({
                    index: indexP,
                    name: item.value,
                    value: e.value,
                  });
                }}
                options={stockRecordsets.map((item) => {
                  return {
                    label: `(${item.StockCode})${item.StockName}`,
                    value: item.StockID,
                  };
                })}
              />
            ) : item?.type === "objectId" &&
              item.value === "StatisticItemID" ? (
              <Select
                placeholder="Lựa chọn"
                value={statisticValue}
                menuPosition="fixed"
                isClearable={true}
                onChange={(e) => {
                  setStatisticValue(e);
                  handleSetSaleInvoiceDetails({
                    index: indexP,
                    name: item.value,
                    value: e.value,
                  });
                }}
                options={statisticsRecordsets.map((item) => {
                  return {
                    label: `(${item.StatisticItemCode})${item.StatisticItemName}`,
                    value: item.StatisticItemID,
                  };
                })}
              />
            ) : item?.type === "date" ? (
              <DatePicker
                customInput={<CustomInputCalendar />}
                wrapperClassName="icon-calendar"
                dateFormat={"dd/MM/yyyy"}
                selected={
                  !!data[item.value] ? new Date(data[item.value]) : null
                }
                onChange={(date) => {
                  handleSetSaleInvoiceDetails({
                    index: indexP,
                    name: item.value,
                    value: date,
                  });
                }}
              />
            ) : (
              <Input
                rounded={6}
                placeholder=""
                size={"md"}
                value={data[item.value] ?? ""}
                disabled={isDetail}
                onChange={(e) => {
                  if (item.type === "number") {
                    if (!isNumeric(e.target.value)) return;
                    handleSetSaleInvoiceDetails({
                      index: indexP,
                      name: item.value,
                      value: e.target.value,
                    });
                  } else {
                    handleSetSaleInvoiceDetails({
                      index: indexP,
                      name: item.value,
                      value: e.target.value,
                    });
                  }
                }}
              />
            )}
          </Td>
        );
      })}
    </Tr>
  );
};

export default TableRow;
