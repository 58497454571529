// Chakra imports
import {
  Tr,
  Td,
  useColorModeValue,
  Input,
  // useToast,
  // Box,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
// Custom components
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CustomInputCalendar from "components/CustomInputCalendar";
import {
  calculateResultEachRow,
  isNumeric,
  normalizeVietnameseText,
} from "common";

const initPurcharInvoiceDetail = {
  VoucherDetailID: null,
  VoucherID: null,
  ItemID: null,
  Description: null,
  StockID: null,
  DebitAccount: null,
  CreditAccount: null,
  Unit: null,
  UnitConvert: null,
  Quantity: null,
  QuantityConvert: 0.0,
  UnitPriceOC: null,
  UnitPrice: null,
  UnitPriceConvertOC: 0.0,
  UnitPriceConvert: null,
  AmountOC: null,
  Amount: null,
  DiscountRate: null,
  DiscountAmountOC: null,
  DiscountAmount: null,
  DiscountAccount: null,
  ImportTaxRate: null,
  ImportTaxAmountOC: null,
  ImportTaxAmount: null,
  ImportTaxAccount: null,
  VATAmountOC: null,
  VATAmount: null,
  VATAccount: null,
  InventoryAccount: null,
  COGAccount: null,
  OutwardPriceOC: null,
  OutwardPrice: null,
  OutwardAmountOC: null,
  OutwardAmount: null,
  DeductionDebitAccount: null,
  CustomsUnitPriceOC: null,
  CustomsUnitPrice: null,
  SpecialConsumeTaxRate: null,
  SpecialConsumeTaxAmount: null,
  SpecialConsumeTaxAmountOC: null,
  SpecialConsumeTaxAccount: null,
  FreightAmountOC: null,
  FreightAmount: null,
  AccountingObjectID: null,
  ContractID: null,
  StatisticItemID: null,
  DepartmentID: null,
  ExpiryDate: null,
  LotNo: null,
  VATPaid: null,
  PaymentVoucherID: null,
  SortOrder: null,
  ConvertRate: null,
  ImportTaxExpenseAmount: null,
  ImportTaxExpenseAmountOC: null,
  DiscountAmountAfterTax: null,
  DiscountAmountAfterTaxOC: null,
  ExpenseItemID: null,
  JobID: null,
  OutwardPriceConvertOC: null,
  OutwardPriceConvert: null,
  TransporterID: null,
  AllocationTime: null,
  ExpenseAccount: null,
  EnvironmentTaxRate: null,
  EnvironmentTaxAmountOC: null,
  EnvironmentTaxAmount: null,
  EnvironmentTaxAccount: null,
  ColumnQuantity1: null,
  ColumnQuantity2: null,
  ColumnQuantity3: null,
  ColumnAmount1: null,
  ColumnAmount2: null,
  TurnOverAmountOC: null,
  TurnOverAmount: null,
  AllocationAmount: null,
  InvoiceTypeID: null,
  PurchasePurposeID: null,
  AccountingObjectTaxID: null,
  AccountingObjectTaxName: null,
  AccountingObjectTaxAddress: null,
  AccountingObjectTaxCode: null,
  VATRate: null,
  InvType: null,
  InvDate: null,
  InvSeries: null,
  InvNo: null,
  VATPostedDate: null,
  ColumnText2: null,
  ColumnText1: null,
  FIFOQuantity: null,
  FIFOQuantityConvert: null,
  Select1: null,
  Select2: null,
  ColumnDate1: null,
  ColumnDate2: null,
  DiscountVATRate: null,
};

const TableRow = ({
  data,
  isLast,
  arrColumns = [],
  tab = 0,
  handleSetPurcharInvoiceDetails,
  indexP,
  AccountingObjectCode,
  AccountingObjectAddress,
  VATDescription,
  isDetail,
  ExchangeRate,
}) => {
  // const toast = useToast();
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [productValue, setProductValue] = useState(null);
  const [purchasePurposeValue, setPurchasePurposeValue] = useState(null);
  const [statisticValue, setStatisticValue] = useState(null);
  const [stockValue, setStockValue] = useState(null);
  const [productSearch, setProductSearch] = useState("");
  const [isSetProduct, setIsSetProduct] = useState(true);
  const [productOptions, setProductOptions] = useState([]);
  const {
    products,
    purchasePurposes,
    invoice,
    stocks,
    statistics,
  } = useSelector((state) => state.invoiceReducer);
  const recordsets = (products?.recordsets ?? []).flat(1);

  const purchasePurposeRecordsets = (purchasePurposes?.recordsets ?? []).flat(
    1
  );
  const stockRecordsets = (stocks?.recordsets ?? []).flat(1);
  const statisticsRecordsets = (statistics?.recordsets ?? []).flat(1);

  useEffect(() => {
    if (!stockValue) {
      const stock = _.flattenDeep(stocks?.recordsets || []).find(
        (recordset) => data.StockID === recordset.StockID
      );

      setStockValue(
        !stock
          ? null
          : {
              label: `(${stock.StockCode})${stock.StockName}`,
              value: stock.StockID,
            }
      );
    }
    if (!statisticValue) {
      const statistic = _.flattenDeep(statistics?.recordsets || []).find(
        (recordset) => data.StatisticItemID === recordset.StatisticItemID
      );

      setStatisticValue(
        !statistic
          ? null
          : {
              label: `(${statistic.StatisticItemCode})${statistic.StatisticItemName}`,
              value: statistic.StatisticItemID,
            }
      );
    }
    if (!purchasePurposeValue) {
      setPurchasePurposeValue({
        label: data.PurchasePurposeCode,
        value: data.PurchasePurposeID,
      });
    }
    if (!productValue) {
      setIsSetProduct(false);
      if (!invoice?.purcharInvoiceDetails[indexP]?.ItemID && !data?.ItemID) {
        // toast({
        //   position: "top-right",
        //   render: () => (
        //     <Box color="white" p={3} bg="red.500">
        //       Lỗi không tìm thấy sản phẩm dòng {indexP + 1}
        //     </Box>
        //   ),
        // });
        return;
      }
      if (!!data?.ItemID) {
        setProductValue({
          label: `(${data?.ItemCode ?? ""})${data?.ItemName}`,
          value: data?.ItemID ?? "",
        });
      } else if (!!invoice?.purcharInvoiceDetails[indexP]?.ItemID) {
        setProductValue({
          label: `(${invoice?.purcharInvoiceDetails[indexP]?.ItemCode ?? ""})${
            invoice?.purcharInvoiceDetails[indexP]?.ItemName ?? ""
          }`,
          value: invoice?.purcharInvoiceDetails[indexP]?.ItemID ?? "",
        });
      }
    }
  }, []);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (productSearch === "") {
        setProductOptions(recordsets.slice(0, 20));
      } else {
        setProductOptions(
          recordsets
            .filter(
              (item) =>
                productSearch === "" ||
                (item?.ItemCode ?? "").includes(productSearch) ||
                (item?.ItemNameNormal ?? "").includes(
                  normalizeVietnameseText(productSearch)
                )
            )
            .slice(0, 20)
        );
      }
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [productSearch]);
  
  return (
    <Tr>
      <Td
        key={-1}
        borderColor={borderColor}
        px={"5px"}
        py={"8px"}
        borderBottom={isLast ? "none" : null}

        // style={item?.style ?? {}}
      >
        <FormControl
          isInvalid={
            (productValue?.value ?? "") === "" && !isDetail && !isSetProduct
          }
        >
          <Select
            className={
              (productValue?.value ?? "") === "" && !isDetail && !isSetProduct
                ? "react-select-error"
                : ""
            }
            placeholder="Lựa chọn"
            value={productValue}
            menuPosition="fixed"
            isClearable={true}
            isDisabled={isDetail}
            onChange={(e) => {
              setProductValue(e);
              setPurchasePurposeValue({
                label: "1",
                value: "68F05655-1AFC-45AB-834A-37FAA500766C",
              });
              handleSetPurcharInvoiceDetails({
                index: indexP,
                name: "selectProduct",
                value: e,
              });
            }}
            options={
              // recordsets
              // .filter(
              //   (item) =>
              //     productSearch === "" ||
              //     (item?.ItemCode ?? "").includes(productSearch) ||
              //     (item?.ItemNameNormal ?? "").includes(
              //       normalizeVietnameseText(productSearch)
              //     )
              // )

              productOptions.map((item) => {
                return {
                  ...calculateResultEachRow(
                    { ...initPurcharInvoiceDetail, ...item, Quantity: 0 },
                    ExchangeRate
                  ),
                  UnitPrice: item.PurchasePrice,
                  UnitPriceOC: item.PurchasePrice / ExchangeRate,
                  label: `(${item?.ItemCode ?? ""})${item?.ItemName ?? ""}`,
                  value: item?.ItemID ?? "",
                  Description: item?.ItemName ?? "",
                  VATAccount: item?.VATAccount ?? "13311",
                  PurchasePurposeID: "68F05655-1AFC-45AB-834A-37FAA500766C",
                  PurchasePurposeCode: "1",
                  AccountingObjectCode,
                  CompanyTaxCode: AccountingObjectCode,
                  AccountingObjectAddress,
                  VATDescription,
                };
              })
            }
            onInputChange={(value) => {
              setProductSearch(value);
            }}
          />

          <FormErrorMessage>
            {(productValue?.value ?? "") === "" &&
              !isDetail &&
              !isSetProduct &&
              "Sản phẩm không được để trống!"}
          </FormErrorMessage>
        </FormControl>
      </Td>
      {arrColumns[tab].slice(1).map((item, index) => {
        // if (index === 0)
        //   return (

        //   );
        return (
          <Td
            key={index}
            borderColor={borderColor}
            px={"5px"}
            py={"8px"}
            borderBottom={isLast ? "none" : null}
            style={item?.style ?? {}}
          >
            {item?.type === "objectId" &&
            item.value === "PurchasePurposeCode" ? (
              <Select
                placeholder="Lựa chọn"
                value={purchasePurposeValue}
                menuPosition="fixed"
                isClearable={true}
                onChange={(e) => {
                  setPurchasePurposeValue(e);
                  handleSetPurcharInvoiceDetails({
                    index: indexP,
                    name: item.value,
                    value: e.value,
                  });
                }}
                options={purchasePurposeRecordsets.map((item) => {
                  return {
                    label: item.PurchasePurposeCode,
                    value: item.PurchasePurposeId,
                  };
                })}
              />
            ) : item?.type === "objectId" && item.value === "StockID" ? (
              <Select
                placeholder="Lựa chọn"
                value={stockValue}
                menuPosition="fixed"
                isClearable={true}
                onChange={(e) => {
                  setStockValue(e);
                  handleSetPurcharInvoiceDetails({
                    index: indexP,
                    name: item.value,
                    value: e.value,
                  });
                }}
                options={stockRecordsets.map((item) => {
                  return {
                    label: `(${item.StockCode})${item.StockName}`,
                    value: item.StockID,
                  };
                })}
              />
            ) : item?.type === "objectId" &&
              item.value === "StatisticItemID" ? (
              <Select
                placeholder="Lựa chọn"
                value={statisticValue}
                menuPosition="fixed"
                isClearable={true}
                onChange={(e) => {
                  setStatisticValue(e);
                  handleSetPurcharInvoiceDetails({
                    index: indexP,
                    name: item.value,
                    value: e.value,
                  });
                }}
                options={statisticsRecordsets.map((item) => {
                  return {
                    label: `(${item.StatisticItemCode})${item.StatisticItemName}`,
                    value: item.StatisticItemID,
                  };
                })}
              />
            ) : item?.type === "date" ? (
              <DatePicker
                customInput={<CustomInputCalendar />}
                wrapperClassName="icon-calendar"
                dateFormat={"dd/MM/yyyy"}
                selected={
                  !!data[item.value] ? new Date(data[item.value]) : null
                }
                onChange={(date) => {
                  handleSetPurcharInvoiceDetails({
                    index: indexP,
                    name: item.value,
                    value: date,
                  });
                }}
              />
            ) : (
              <Input
                rounded={6}
                placeholder=""
                size={"md"}
                value={data[item.value] ?? ""}
                disabled={isDetail}
                onChange={(e) => {
                  if (item.type === "number") {
                    if (!isNumeric(e.target.value)) return;
                    handleSetPurcharInvoiceDetails({
                      index: indexP,
                      name: item.value,
                      value: e.target.value,
                    });
                  } else {
                    handleSetPurcharInvoiceDetails({
                      index: indexP,
                      name: item.value,
                      value: e.target.value,
                    });
                  }
                }}
              />
            )}
          </Td>
        );
      })}
    </Tr>
  );
};

export default TableRow;
