import React, { useState, useEffect } from 'react';
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  useColorModeValue,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
} from '@chakra-ui/react';
import Select from 'react-select';
// Assets
import signInImage from 'assets/img/signInImage.png';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import fac_logo from 'assets/img/fac_logo.png';
import { accountActions, companyActions } from 'redux/actions';
import Error from 'components/Error';
import queryString from 'query-string';

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useToast();
  const { isLogIn } = useSelector((state) => state.accountReducer);
  const { isCompanysGetting, companys } = useSelector(
    (state) => state.companyReducer
  );
  const [company, setCompany] = useState(null);

  const [loadData, setLoadData] = useState(null);
  const accountSchema = yup.object().shape({
    email: yup
      .string()
      .email('Email không đúng định dạng!')
      .required('Email không được để trống!'),
    password: yup.string().required('Mật khẩu không được để trống!'),
  });
  const [accountInfo, setAccountInfo] = useState({
    email: 'admin@admin.vn',
    password: 'password1',
  });
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  // Chakra color mode
  const textColor = useColorModeValue('gray.700', 'white');
  const bgForm = useColorModeValue('white', 'navy.800');

  const bgIcons = useColorModeValue('trasnparent', 'navy.700');
  const bgIconsHover = useColorModeValue('gray.50', 'whiteAlpha.100');

  const onSubmit = (values) => {
    dispatch(
      accountActions.accountLogin(values, {
        success: (data) => {
          // setMessage('');
          setIsOpen(true);
          const token = data.tokens.access.token;
          const refreshtoken = data.tokens.refresh.token;

          const user = data.user;
          localStorage.setItem('token', token);
          setLoadData({
            refreshtoken,
            user,
          });

          dispatch(
            companyActions.getCompanys(queryString.stringify({ limit: 999999 }))
          );
        },
        failed: (mess) => {
          // setMessage(mess);
        },
      })
    );
  };

  function handleClose() {
    setIsOpen(false);
    setCompany(null);
    setLoadData(null);
    localStorage.clear();
  }

  function loginToCompany(e) {
    e.preventDefault();
    if (!company || !loadData) return;
    const {
      refreshtoken,
      user: { id, name, email, roleId, address, gender },
    } = loadData;

    dispatch(
      accountActions.updateAccount(
        id,
        {
          name,
          email,
          roleId,
          database: company.database,
          accountId: company.accountId,
          address,
          gender,
        },
        {
          success: () => {
            localStorage.setItem('roleId', roleId);
            localStorage.setItem('accountId', company.accountId);
            localStorage.setItem('refreshtoken', refreshtoken);
            localStorage.setItem('id', id);
            localStorage.setItem('companyName', company.label);
            history.push('/');
          },
          failed: () => {
            toast({
              position: 'top-right',
              render: () => (
                <Box color="white" p={3} bg="red.500">
                  Lỗi truy cập công ty
                </Box>
              ),
            });
          },
        }
      )
    );
  }

  return (
    <Flex position="relative">
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        onClose={handleClose}
      >
        <ModalOverlay />
        <form onSubmit={loginToCompany}>
          <ModalContent>
            <ModalHeader>Truy cập vào dữ liệu công ty</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Select
                placeholder="Lựa chọn"
                value={company}
                menuPosition="fixed"
                isClearable={true}
                onChange={(e) => {
                  setCompany(e);
                  // setProductValue(e);
                }}
                options={companys.results.map((result) => ({
                  label: result.name,
                  value: result.id,
                  database: result.database,
                  accountId: result.accountId,
                }))}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                isLoading={isCompanysGetting}
                loadingText="Đang xử lý"
                colorScheme="blue"
                type="submit"
                mr={3}
              >
                Truy cập
              </Button>
              <Button onClick={handleClose}>Đóng</Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
      <Formik
        initialValues={accountInfo}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={accountSchema}
      >
        {({ values, handleSubmit, errors, touched, handleChange }) => {
          return (
            <Flex
              minH={{ md: '1000px' }}
              h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
              w="100%"
              maxW="1044px"
              mx="auto"
              justifyContent="space-between"
              mb="30px"
              pt={{ md: '0px' }}
            >
              <Flex
                w="100%"
                h="100%"
                alignItems="center"
                justifyContent="center"
                mb="60px"
                mt={{ base: '50px', md: '20px' }}
              >
                <Flex
                  zIndex="2"
                  direction="column"
                  w="445px"
                  background="transparent"
                  borderRadius="15px"
                  p="40px"
                  mx={{ base: '100px' }}
                  m={{ base: '20px', md: 'auto' }}
                  bg={bgForm}
                  boxShadow={useColorModeValue(
                    '0px 5px 14px rgba(0, 0, 0, 0.05)',
                    'unset'
                  )}
                >
                  <Text
                    fontSize="xl"
                    color={textColor}
                    fontWeight="bold"
                    textAlign="center"
                    mb="22px"
                  >
                    ĐĂNG NHẬP
                  </Text>
                  <HStack spacing="15px" justify="center" mb="22px">
                    <Flex
                      justify="center"
                      align="center"
                      w="200px"
                      h="100px"
                      borderRadius="8px"
                      border={useColorModeValue('1px solid', '0px')}
                      borderColor="gray.200"
                      cursor="pointer"
                      transition="all .25s ease"
                      bg={bgIcons}
                      _hover={{ bg: bgIconsHover }}
                    >
                      <Image src={fac_logo} borderRadius="15px" />
                    </Flex>
                  </HStack>
                  <Form onSubmit={handleSubmit}>
                    <FormControl>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Tài khoản
                      </FormLabel>
                      <Input
                        variant="auth"
                        fontSize="sm"
                        ms="4px"
                        type="text"
                        placeholder="Email"
                        size="lg"
                        id={'email'}
                        name={'email'}
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && (
                        <Error message={errors.email} />
                      )}
                      <FormLabel
                        mt="12px"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="normal"
                      >
                        Mật khẩu
                      </FormLabel>
                      <Input
                        variant="auth"
                        fontSize="sm"
                        ms="4px"
                        type="password"
                        placeholder="Mật khẩu"
                        size="lg"
                        id={'password'}
                        name={'password'}
                        value={values.password}
                        onChange={handleChange}
                      />
                      {errors.password && touched.password && (
                        <Error message={errors.password} />
                      )}
                      {!!message && <Error message={message} />}

                      <Button
                        fontSize="10px"
                        bg={'#054169'}
                        fontWeight="bold"
                        w="100%"
                        h="45"
                        mb="24px"
                        ms={'4px'}
                        color={'white'}
                        type={'submit'}
                        mt="12px"
                        isLoading={isLogIn}
                        loadingText="Submitting"
                        onClick={handleSubmit}
                      >
                        ĐĂNG NHẬP
                      </Button>
                    </FormControl>
                  </Form>
                </Flex>
              </Flex>
              <Box
                overflowX="hidden"
                h="100%"
                w="100%"
                left="0px"
                position="absolute"
                bgImage={signInImage}
              >
                <Box
                  w="100%"
                  h="100%"
                  bgSize="cover"
                  bg="blue.500"
                  opacity="0.8"
                ></Box>
              </Box>
            </Flex>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default Login;
