// Chakra Imports
import {
  Flex,
  Link,
  Image,
  Text,
  useColorModeValue,
  HStack,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Divider,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaSignOutAlt } from "react-icons/fa";
import fac_logo from "assets/img/fac_logo.png";
import { useHistory } from "react-router-dom";
import { ProfileIcon } from "components/Icons/Icons";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { accountActions, invoiceActions } from "redux/actions";
import queryString from "query-string";

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const {
    variant,
    children,
    fixed,
    secondary,
    brandText,
    onOpen,
    ...rest
  } = props;

  const toast = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const companyName = localStorage.getItem("companyName");
  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("white", "gray.200");
  let secondaryText =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("#054169", "gray.200");
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "none";
  let navbarShadow = "6";
  let navbarBg = "white";
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  if (props.fixed === true)
    if (scrolled === true) {
      navbarPosition = "fixed";
      navbarShadow = useColorModeValue(
        "0px 7px 23px rgba(0, 0, 0, 0.05)",
        "none"
      );
      navbarBg = useColorModeValue(
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
      );
      navbarBorder = useColorModeValue("#FFFFFF", "rgba(255, 255, 255, 0.31)");
      navbarFilter = useColorModeValue(
        "none",
        "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
      );
    }
  if (props.secondary) {
    navbarBackdrop = "none";
    navbarPosition = "absolute";
    mainText = "white";
    secondaryText = "white";
    secondaryMargin = "22px";
    paddingX = "30px";
  }
  let navbarIcon =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("#054169", "gray.200");
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  function handleSyncProducts() {
    dispatch(
      invoiceActions.getProducts(
        queryString.stringify({
          procName: "Proc_SelectItem",
        }),
        {
          success: () => {
            toast({
              position: "top-right",
              description: "Đồng bộ sản phẩm thành công",
              status: "success",
            });
          },
          failed: () => {
            toast({
              position: "top-right",
              description: "Đồng bộ sản phẩm thất bại",
              status: "error",
            });
          },
        }
      )
    );
  }
  return (
    <Flex
      position={navbarPosition}
      boxShadow={"0px 7px 23px rgba(0, 0, 0, 0.1)"}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      borderRadius="16px"
      display="flex"
      minH="75px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={"30px"}
      top={"15px"}
      px={{
        sm: paddingX,
        md: "30px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      w={"calc(100% - 335px)"}
    >
      <Flex
        w="100%"
        flexDirection={["column", "column", "row", "row"]}
        alignItems={["", "", "center", "center"]}
      >
        <HStack alignItems={"center"} mb={{ sm: "8px", md: "0px" }}>
          <Link
            color={mainText}
            href="/"
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            _hover={{ color: { mainText } }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
          >
            <Image src={fac_logo} borderRadius="15px" />
          </Link>
          <Text
            fontSize={[16, 18, 20, 25]}
            fontWeight={"bold"}
            color={"#054169"}
          >
            HỆ THỐNG CẬP NHẬT HÓA ĐƠN
            <Text
              fontSize={[12, 12, 12, 12]}
              fontWeight={"bold"}
              color={"#054169"}
            >
              {companyName}
            </Text>
          </Text>
        </HStack>
        <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
          <HStack>
            <Button
              onClick={() => handleSyncProducts()}
              opacity={0.7}
              fontSize={16}
              fontWeight={"bold"}
              color={"#F07504"}
              textTransform={"uppercase"}
              colorScheme="orange"
              variant="outline"
            >
              Đồng bộ sản phẩm
            </Button>
            <Divider
              h={5}
              size={"xl"}
              colorScheme={"cyan"}
              orientation="vertical"
            />
            {/* <Text
              opacity={0.7}
              fontSize={16}
              fontWeight={'bold'}
              color={'#1E1E1E'}
              textTransform={'uppercase'}
            >
              bán hàng
            </Text> */}
            <Menu>
              <MenuButton>
                <HStack alignItems={"center"}>
                  <ProfileIcon color={navbarIcon} w="18px" h="18px" />
                  <Text
                    as="span"
                    color={"#054169"}
                    fontWeight={"700"}
                    fontSize={14}
                  >
                    {currentAccount?.name ?? "hadv"}
                  </Text>
                  <ChevronDownIcon color={navbarIcon} w="15px" h="15px" />
                </HStack>
              </MenuButton>
              <MenuList p="16px 8px" bg={"white"}>
                <Flex flexDirection="column">
                  <MenuItem borderRadius="8px" mb="10px">
                    <Button
                      onClick={() => {
                        dispatch(invoiceActions.resetInvoice());
                        dispatch(
                          accountActions.accountLogOut({
                            refreshToken: localStorage.getItem("refreshtoken"),
                          })
                        );
                        history.push("/auth/login");
                      }}
                      leftIcon={<FaSignOutAlt />}
                      variant={"ghost"}
                    >
                      Đăng xuất
                    </Button>
                  </MenuItem>
                </Flex>
              </MenuList>
            </Menu>
          </HStack>
        </Box>
      </Flex>
    </Flex>
  );
}
