import { normalizeVietnameseText } from "common";
import _ from "lodash";
import moment from "moment";
import TypeActions from "redux/typeActions";

const initialState = {
  isGettingInvoice: false,
  isGettingInvoices: false,
  isGettingProducts: false,
  isGettingInvoiceTypes: false,
  isGettingStocks: false,
  isGettingStatistics: false,
  isGettingPurchasePurposes: false,
  isCreatingInvoice: false,
  isSavingInvoices: false,
  isSavingInvoice: false,
  isUpdatingInvoiceStatus: false,
  query: {
    page: 1,
    limit: 50,
    saveInvoiceStatus: 0,
    month: moment().month() + 1,
    year: moment().year(),
  },
  invoice: {},
  invoices: {},
  products: {},
  purchasePurposes: {},
  invoiceTypes: {},
  stocks: {},
  statistics: {},
  saveInvoices: {},
  saveInvoice: {},
  errors: {
    getInvoice: "",
    getInvoices: "",
    getProducts: "",
    getStocks: "",
    getStatistics: "",
    getPurchasePurposes: "",
    saveInvoices: "",
    saveInvoice: "",
  },
};

const invoiceReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case TypeActions.GET_INVOICE_REQUEST:
      return {
        ...state,

        isGettingInvoice: true,
        errors: {
          ...state.errors,
          getInvoice: "",
        },
      };
    case TypeActions.GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: actions.data || {},
        isGettingInvoice: false,
        errors: {
          ...state.errors,
          getInvoice: "",
        },
      };
    case TypeActions.GET_INVOICE_FAILED:
      return {
        ...state,
        invoice: {},
        isGettingInvoice: false,
        errors: {
          ...state.errors,
          getInvoice: actions.error,
        },
      };

    case TypeActions.GET_INVOICES_REQUEST:
      return {
        ...state,

        isGettingInvoices: true,
        errors: {
          ...state.errors,
          getInvoices: "",
        },
      };
    case TypeActions.GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: actions.data || {},
        isGettingInvoices: false,
        errors: {
          ...state.errors,
          getInvoices: "",
        },
      };
    case TypeActions.GET_INVOICES_FAILED:
      return {
        ...state,
        invoice: {},
        isGettingInvoices: false,
        errors: {
          ...state.errors,
          getInvoices: actions.error,
        },
      };

    case TypeActions.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        isGettingProducts: true,
        errors: {
          ...state.errors,
          getProducts: "",
        },
      };
    case TypeActions.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: (actions.data?.recordsets ?? []).length
          ? {
              ...actions.data,
              recordsets: actions.data.recordsets.map((recordset) =>
                _.isArray(recordset)
                  ? recordset.map((r) => ({
                      ...r,
                      ItemNameNormal: !!r.ItemName
                        ? normalizeVietnameseText(r.ItemName)
                        : r.ItemName,
                    }))
                  : {
                      ...recordset,
                      ItemNameNormal: !!recordset.ItemName
                        ? normalizeVietnameseText(recordset.ItemName)
                        : recordset.ItemName,
                    }
              ),
            }
          : {},
        isGettingProducts: false,
        errors: {
          ...state.errors,
          getProducts: "",
        },
      };
    case TypeActions.GET_PRODUCTS_FAILED:
      return {
        ...state,
        products: { recordsets: [] },
        isGettingProducts: false,
        errors: {
          ...state.errors,
          getProducts: actions.error,
        },
      };

    case TypeActions.GET_PURCHASE_PURPOSES_REQUEST:
      return {
        ...state,
        isGettingPurchasePurposes: true,
        errors: {
          ...state.errors,
          getPurchasePurposes: "",
        },
      };
    case TypeActions.GET_PURCHASE_PURPOSES_SUCCESS:
      return {
        ...state,
        purchasePurposes: actions.data || {},
        isGettingPurchasePurposes: false,
        errors: {
          ...state.errors,
          getPurchasePurposes: "",
        },
      };
    case TypeActions.GET_PURCHASE_PURPOSES_FAILED:
      return {
        ...state,
        purchasePurposes: {
          recordsets: [],
        },
        isGettingPurchasePurposes: false,
        errors: {
          ...state.errors,
          getPurchasePurposes: actions.error,
        },
      };

    case TypeActions.GET_STOCKS_REQUEST:
      return {
        ...state,
        isGettingStocks: true,
        errors: {
          ...state.errors,
          getStocks: "",
        },
      };
    case TypeActions.GET_STOCKS_SUCCESS:
      return {
        ...state,
        stocks: actions.data || {},
        isGettingStocks: false,
        errors: {
          ...state.errors,
          getStocks: "",
        },
      };
    case TypeActions.GET_STOCKS_FAILED:
      return {
        ...state,
        stocks: { recordsets: [] },
        isGettingStocks: false,
        errors: {
          ...state.errors,
          getStocks: actions.error,
        },
      };

    case TypeActions.GET_STATISTICS_REQUEST:
      return {
        ...state,
        isGettingStatistics: true,
        errors: {
          ...state.errors,
          getStatistics: "",
        },
      };
    case TypeActions.GET_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: actions.data || {},
        isGettingStatistics: false,
        errors: {
          ...state.errors,
          getStatistics: "",
        },
      };
    case TypeActions.GET_STATISTICS_FAILED:
      return {
        ...state,
        statistics: { recordsets: [] },
        isGettingStatistics: false,
        errors: {
          ...state.errors,
          getStatistics: actions.error,
        },
      };

    case TypeActions.CREATE_INVOICE_REQUEST:
      return {
        ...state,
        isCreatingInvoice: true,
        errors: {
          ...state.errors,
          getProducts: "",
        },
      };
    case TypeActions.CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        isCreatingInvoice: false,
        errors: {
          ...state.errors,
          getProducts: "",
        },
      };
    case TypeActions.CREATE_INVOICE_FAILED:
      return {
        ...state,
        isCreatingInvoice: false,
        errors: {
          ...state.errors,
        },
      };

    case TypeActions.SAVE_INVOICES_REQUEST:
      return {
        ...state,

        isSavingInvoices: true,
        errors: {
          ...state.errors,
          saveInvoices: "",
        },
      };
    case TypeActions.SAVE_INVOICES_SUCCESS:
      return {
        ...state,
        saveInvoices: actions.data || {},
        isSavingInvoices: false,
        errors: {
          ...state.errors,
          saveInvoices: "",
        },
      };
    case TypeActions.SAVE_INVOICES_FAILED:
      return {
        ...state,
        saveInvoices: {},
        isSavingInvoices: false,
        errors: {
          ...state.errors,
          saveInvoices: actions.error,
        },
      };

    case TypeActions.SAVE_INVOICE_REQUEST:
      return {
        ...state,

        isSavingInvoice: true,
        errors: {
          ...state.errors,
          saveInvoice: "",
        },
      };
    case TypeActions.SAVE_INVOICE_SUCCESS:
      return {
        ...state,
        saveInvoice: actions.data || {},
        isSavingInvoice: false,
        errors: {
          ...state.errors,
          saveInvoice: "",
        },
      };
    case TypeActions.SAVE_INVOICE_FAILED:
      return {
        ...state,
        saveInvoice: {},
        isSavingInvoice: false,
        errors: {
          ...state.errors,
          saveInvoice: actions.error,
        },
      };

    case TypeActions.UPDATE_SAVE_INVOICE_STATUS_REQUEST:
      return {
        ...state,

        isUpdatingInvoiceStatus: true,
      };
    case TypeActions.UPDATE_SAVE_INVOICE_STATUS_SUCCESS:
      return {
        ...state,
        isUpdatingInvoiceStatus: false,
      };
    case TypeActions.UPDATE_SAVE_INVOICE_STATUS_FAILED:
      return {
        ...state,
        isUpdatingInvoiceStatus: false,
      };

    case TypeActions.RESET_INVOICE_REQUEST:
      return {
        ...state,
        products: {},
        purchasePurposes: {},
        stocks: {},
        statistics: {},
      };

    case TypeActions.SET_QUERY_ORDER:
      return {
        ...state,
        query: actions.query,
      };

    case TypeActions.GET_INVOICE_TYPES_REQUEST:
      return {
        ...state,
        isGettingInvoiceTypes: true,
        errors: {
          ...state.errors,
        },
      };
    case TypeActions.GET_INVOICE_TYPES_SUCCESS:
      return {
        ...state,
        invoiceTypes: actions.data || {},
        isGettingInvoiceTypes: false,
        errors: {
          ...state.errors,
        },
      };
    case TypeActions.GET_INVOICE_TYPES_FAILED:
      return {
        ...state,
        invoiceTypes: { recordsets: [] },
        isGettingInvoiceTypes: false,
      };

    default:
      return {
        ...state,
      };
  }
};
export default invoiceReducer;
