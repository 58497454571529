export const arrColumns = [
  [
    {
      label: "Mã hàng",
      value: "ItemCode",
      style: {},
      textStyle: {},
      headerStyle: {
        minWidth: "500px !important",
      },
      className: "min-w-400",
    },
    {
      label: "Tên hàng",
      value: "ItemName",
      style: {},
      textStyle: {},
      headerStyle: {},
      className: "min-w-300",
    },
    {
      label: "Kho",
      value: "StockID",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "objectId",
    },
    {
      label: "Đơn vị tính",
      value: "Unit",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Tài khoản Nợ",
      value: "DebitAccount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Tài khoản Có",
      value: "CreditAccount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Số lượng",
      value: "Quantity",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Đơn giá",
      value: "UnitPrice",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Thành tiền",
      value: "Amount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Tỷ lệ chiết khấu",
      value: "DiscountRate",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Tiền chiết khấu",
      value: "DiscountAmount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      // AccountingObjectID
      label: "Nhà cung cấp",
      value: "AccountingObjectCode",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Khoản mục CP",
      value: "x4",
      style: {},

      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Hợp đồng",
      value: "ContractID",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Mã thống kê",
      value: "StatisticItemID",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "objectId",
    },
    {
      label: "Chứng từ liên quan",
      value: "DocumentIncluded",
      style: {},

      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Phòng ban",
      value: "DepartmentID",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
  ],
  [
    {
      label: "Mã hàng",
      value: "ItemID",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Diễn giải",
      value: "VATDescription",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Thuế suất",
      value: "VATRate",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Tiền thuế GTGT",
      value: "VATAmount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "TK thuế GTGT",
      value: "VATAccount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "string",
    },
    {
      label: "Loại hóa đơn",
      value: "InvType",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Số hóa đơn",
      value: "InvNo",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Ngày hóa đơn",
      value: "VATPostedDate",
      style: {},
      headerStyle: { textAlign: "center" },
      textStyle: { textAlign: "center" },
      type: "date",
    },
    {
      label: "Ngày HT thuế",
      value: "VATPostedDate",
      style: {},
      headerStyle: { textAlign: "center" },
      textStyle: { textAlign: "center" },
      type: "date",
    },
    {
      label: "Nhóm HHDV",
      value: "PurchasePurposeCode",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "objectId",
    },
    {
      label: "Đối tượng thuế",
      value: "CompanyTaxCode",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Mã số thuế(Thuế)",
      value: "CompanyTaxCode",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Địa chỉ",
      value: "AccountingObjectAddress",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Tiền vốn",
      value: "OutwardAmount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
  ],
  [
    {
      label: "Mã hàng",
      value: "ItemID",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Tên hàng",
      value: "ItemName",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Chi phí",
      value: "x3",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Thuế suất",
      value: "VATRate",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Tiền thuế GTGT",
      value: "VATAmount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "TK thuế GTGT",
      value: "VATAccount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "string",
    },
    {
      label: "Đơn giá vốn",
      value: "OutwardPrice",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Giá vốn chuyển đổi",
      value: "OutwardPriceOC",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Tiền vốn",
      value: "OutwardAmount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
  ],
  [
    {
      label: "Mã hàng",
      value: "ItemID",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Tên hàng",
      value: "ItemName",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Tiền CK",
      value: "DiscountAmount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
  ],
];

export const purchaseInvoiceRequireFields = {
  VoucherType: { message: "Hình thức thanh toán" },
  // BillReceived: { message: 'Đã nhận hoá đơn' },
  // Không có tỷ giá hối đoái,
  // IsImportPurchase: { message: 'Là hàng mua nhập khẩu' }, // Là hàng mua nhập khẩu => mặc định 0 - mua hàng trong nước,
  // Không có Thuế tiêu thụ đặc biệt: 1 Có; 0: không có thuế tiêu thụ đặc biệt
  // TotalAmountOC: { message: 'Tổng tiền hàng' }, // Tổng tiền hàng,
  // TotalAmount: { message: 'Tổng tiền hàng quy đổi' },
  // Không có Tổng tiền thuế nhập khẩu
  // Không có Tổng tiền thuế nhập khẩu quy đổi
  // Không có Tổng tiền thuế GTGT
  // Không có Tổng tiền thuế GTGT quy đổi
  // TotalDiscountAmountOC: {
  //   message: 'Tổng tiền chiết khấu',
  // },
  // TotalDiscountAmount: {
  //   message: 'Tổng tiền chiết khấu quy đổi',
  // },
  // Không có Tổng phí vận chuyển
  // Không có Tổng phí vận chuyển quy đổi
  // Không có Tổng giá trị nhập kho
  // Không có Tổng giá trị nhập kho quy đổi
  PostedDate: {
    message: "Ngày ghi sổ",
  },
  // DueDate: {
  //   message: 'Hạn thanh toán',
  // },
  // InvSeries: {
  //   message: 'Kí hiệu',
  // },
  // Không có Bảng kê
  // Không có Đơn vị chuyển đôỉ
};

export const purchaseInvoiceDetailRequireFields = {
  // VoucherDetailID: { message: 'PK' },
  // VoucherID: { message: 'FK' },
  Quantity: { message: "Số lượng", type: "number", min: 1 },
  // QuantityConvert: { message: 'Số lượng quy đổi' },
  // UnitPriceOC: { message: 'Đơn giá (mua)' },
  UnitPrice: { message: "Đơn giá quy đổi", type: "number", min: 1 },
  // UnitPriceConvertOC: { message: 'Đơn giá chuyển đổi' },
  // UnitPriceConvert: { message: 'Đơn giá chuyển đổi quy đổi' },
  // AmountOC: { message: 'Thành tiền' },
  Amount: { message: "Thành tiền quy đổi", type: "number", min: 1 },
  // DiscountRate: { message: 'Tỉ lệ chiết khấu', type: 'number', min: 1 },
  // DiscountAmountOC: { message: 'Tiền chiêt khấu', type: 'number', min: 1 },
  // DiscountAmount: { message: 'Tiền chiêt khấu quy đổi' },
  // ImportTaxRate: { message: 'Thuế suất nhập khẩu' },
  // ImportTaxAmountOC: { message: 'Tiền thuế nhập khẩu' },
  // ImportTaxAmount: { message: 'Tiền thuế  nhập khẩu quy đổi' },
  // VATAmountOC: { message: 'Tiền thuế GTGT' },
  // VATAmount: { message: 'Tiền thuế GTGT quy đổi' },
  // OutwardPriceOC: { message: 'Đơn giá nhập/Xuất' },
  // OutwardPrice: { message: 'Đơn giá nhập/Xuất (Quy đổi)' },
  // OutwardAmountOC: { message: 'Giá trị nhập/Xuất' },
  // OutwardAmount: { message: 'Giá trị nhập/Xuất (Quy đổi)' },
  // CustomsUnitPriceOC: { message: 'Giá hải quan áp' },
  // CustomsUnitPrice: { message: 'Giá hải quan áp quy đổi' },
  // SpecialConsumeTaxRate: { message: '%Thuế tiêu thụ đặc biệt' },
  // SpecialConsumeTaxAmount: {
  //   message: 'Thuế tiêu thụ đặc biệt quy đổi',
  // },
  // SpecialConsumeTaxAmountOC: { message: 'Thuế tiêu thụ đặc biệt' },
  // FreightAmountOC: { message: 'Phí vận chuyển' },
  // FreightAmount: { message: 'Phí vận chuyển quy đổi' },
  // VATPaid: { message: 'Đã nộp thuế VAT hay chưa' },
  // ImportTaxExpenseAmount: { message: 'ImportTaxExpenseAmount' },
  // ImportTaxExpenseAmountOC: { message: 'ImportTaxExpenseAmountOC' },
  // DiscountAmountAfterTax: { message: 'DiscountAmountAfterTax' },
  // DiscountAmountAfterTaxOC: { message: 'DiscountAmountAfterTaxOC' },
  // OutwardPriceConvertOC: { message: 'Đơn giá nhập/xuất chuyển đổi' },
  // OutwardPriceConvert: {
  //   message: 'Đơn giá nhập/xuất chuyển đổi quy đổi',
  // },
  // AllocationTime: { message: 'Số lần phân bổ' },
  // EnvironmentTaxRate: { message: 'EnvironmentTaxRate' },
  // EnvironmentTaxAmountOC: { message: 'EnvironmentTaxAmountOC' },
  // EnvironmentTaxAmount: { message: 'EnvironmentTaxAmount' },
  // ColumnQuantity1: { message: 'ColumnQuantity1' },
  // ColumnQuantity2: { message: 'ColumnQuantity2' },
  // ColumnQuantity3: { message: 'ColumnQuantity3' },
  // ColumnAmount1: { message: 'ColumnAmount1' },
  // ColumnAmount2: { message: 'ColumnAmount2' },
  // TurnOverAmountOC: { message: 'TurnOverAmountOC' },
  // TurnOverAmount: { message: 'TurnOverAmount' },
  // AllocationAmount: { message: 'AllocationAmount' },
  // VATRate: { message: 'Thuế suất GTGT' },
  // FIFOQuantity: { message: 'FIFOQuantity' },
  // FIFOQuantityConvert: { message: 'FIFOQuantityConvert' },
  // DiscountVATRate: { message: 'DiscountVATRate' },
};

export const arrSellColumns = [
  [
    {
      label: "Mã hàng",
      value: "ItemCode",
      style: {},
      textStyle: {},
      headerStyle: {
        minWidth: "500px !important",
      },
      className: "min-w-400",
    },
    {
      label: "Tên hàng",
      value: "ItemName",
      style: {},
      textStyle: {},
      headerStyle: {},
      className: "min-w-300",
    },
    {
      label: "Kho",
      value: "StockID",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "objectId",
    },
    {
      label: "Đơn vị tính",
      value: "Unit",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Tài khoản Nợ",
      value: "DebitAccount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Tài khoản Có",
      value: "CreditAccount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Số lượng",
      value: "Quantity",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Đơn giá",
      value: "UnitPrice",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Thành tiền",
      value: "Amount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Tỷ lệ chiết khấu",
      value: "DiscountRate",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Tiền chiết khấu",
      value: "DiscountAmount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      // AccountingObjectID
      label: "Nhà cung cấp",
      value: "AccountingObjectCode",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Khoản mục CP",
      value: "x4",
      style: {},

      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Hợp đồng",
      value: "ContractID",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Mã thống kê",
      value: "StatisticItemID",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "objectId",
    },
    {
      label: "Chứng từ liên quan",
      value: "DocumentIncluded",
      style: {},

      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Phòng ban",
      value: "DepartmentID",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
  ],
  [
    {
      label: "Mã hàng",
      value: "ItemCode",
      style: {},
      textStyle: {},
      headerStyle: {
        minWidth: "500px !important",
      },
      className: "min-w-400",
    },
    {
      label: "Tên hàng",
      value: "ItemName",
      style: {},
      textStyle: {},
      headerStyle: {},
      className: "min-w-300",
    },
    {
      label: "Thuế suất",
      value: "VATRate",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Tiền thuế GTGT",
      value: "VATAmount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "TK thuế GTGT",
      value: "VATAccount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "string",
    },
    {
      label: "Kho",
      value: "StockID",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "objectId",
    },
    {
      label: "Tài khoản kho",
      value: "StockAccountNumber",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "objectId",
    },
    {
      label: "TK giá vốn",
      value: "COGAccount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Đơn giá vốn",
      value: "OutwardPrice",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Tiền vốn",
      value: "OutwardAmount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
  ],
  [
    {
      label: "Mã hàng",
      value: "ItemID",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Diễn giải",
      value: "VATDescription",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Thuế suất",
      value: "VATRate",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Tiền thuế GTGT",
      value: "VATAmount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "TK thuế GTGT",
      value: "VATAccount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "string",
    },
    {
      label: "Loại hóa đơn",
      value: "InvType",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Số hóa đơn",
      value: "InvNo",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Ngày hóa đơn",
      value: "VATPostedDate",
      style: {},
      headerStyle: { textAlign: "center" },
      textStyle: { textAlign: "center" },
      type: "date",
    },
    {
      label: "Ngày HT thuế",
      value: "VATPostedDate",
      style: {},
      headerStyle: { textAlign: "center" },
      textStyle: { textAlign: "center" },
      type: "date",
    },
    {
      label: "Nhóm HHDV",
      value: "PurchasePurposeCode",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "objectId",
    },
    {
      label: "Đối tượng thuế",
      value: "CompanyTaxCode",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Mã số thuế(Thuế)",
      value: "CompanyTaxCode",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
    {
      label: "Địa chỉ",
      value: "AccountingObjectAddress",
      style: {},
      headerStyle: {},
      textStyle: {},
    },
    {
      label: "Tiền vốn",
      value: "OutwardAmount",
      style: {},
      headerStyle: {},
      textStyle: {},
      type: "number",
    },
  ],
];

export const saleInvoiceRequireFields = {
  VoucherType: { message: "Hình thức thanh toán" },
  // BillReceived: { message: 'Đã nhận hoá đơn' },
  // Không có tỷ giá hối đoái,
  // IsImportPurchase: { message: 'Là hàng mua nhập khẩu' }, // Là hàng mua nhập khẩu => mặc định 0 - mua hàng trong nước,
  // Không có Thuế tiêu thụ đặc biệt: 1 Có; 0: không có thuế tiêu thụ đặc biệt
  // TotalAmountOC: { message: 'Tổng tiền hàng' }, // Tổng tiền hàng,
  // TotalAmount: { message: 'Tổng tiền hàng quy đổi' },
  // Không có Tổng tiền thuế nhập khẩu
  // Không có Tổng tiền thuế nhập khẩu quy đổi
  // Không có Tổng tiền thuế GTGT
  // Không có Tổng tiền thuế GTGT quy đổi
  // TotalDiscountAmountOC: {
  //   message: 'Tổng tiền chiết khấu',
  // },
  // TotalDiscountAmount: {
  //   message: 'Tổng tiền chiết khấu quy đổi',
  // },
  // Không có Tổng phí vận chuyển
  // Không có Tổng phí vận chuyển quy đổi
  // Không có Tổng giá trị nhập kho
  // Không có Tổng giá trị nhập kho quy đổi
  PostedDate: {
    message: "Ngày ghi sổ",
  },
  // DueDate: {
  //   message: 'Hạn thanh toán',
  // },
  InvSeries: {
    message: "Kí hiệu",
  },
  // Không có Bảng kê
  // Không có Đơn vị chuyển đôỉ
};

export const saleInvoiceDetailRequireFields = {
  // VoucherDetailID: { message: 'PK' },
  // VoucherID: { message: 'FK' },
  Quantity: { message: "Số lượng", type: "number", min: 1 },
  // QuantityConvert: { message: 'Số lượng quy đổi' },
  // UnitPriceOC: { message: 'Đơn giá (mua)' },
  UnitPrice: { message: "Đơn giá quy đổi", type: "number", min: 1 },
  // UnitPriceConvertOC: { message: 'Đơn giá chuyển đổi' },
  // UnitPriceConvert: { message: 'Đơn giá chuyển đổi quy đổi' },
  // AmountOC: { message: 'Thành tiền' },
  Amount: { message: "Thành tiền quy đổi", type: "number", min: 1 },
};
